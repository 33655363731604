/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { makeStyles, Card, CardContent, TableCell, Table, TableBody, TableRow, Link, Typography, Button } from '@material-ui/core'
import { useEffect } from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'
import EventService, { Event } from 'services/events'
import { Loader } from 'services/context'
import { useState } from 'react'
import { EVENT_LOADER_KEY } from '../list'
import Image from 'material-ui-image'
import Form from './form'
import Scores from './scores'
import moment from 'moment'
import { Fullname } from 'components/Fullname'
import Gallery from 'react-grid-gallery'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2)
  },
  content: {
    padding: 0
  }
}))
/**
 * @type {Event}
 */
const EVENT = {}

export default props => {
  const {
    startLoader,
    finishLoader,
    globalLoader
  } = Loader('globalLoader')
  const [event, setEvent] = useState(EVENT)
  const params = useParams()
  const [processOpen, setProcessOpen] = useState(false)
  const componentDidMount = () => {
    getEvent()
  }
  const getEvent = () => {
    startLoader(EVENT_LOADER_KEY, "Fetching event")
    EventService.eventDetail(params.publisher, params.game, params.event).then(g => {
      g.started_at = new Date(g.started_at)
      g.ended_at = new Date(g.ended_at)
      setEvent(g)
    }).catch(e => {

    }).finally(() => {
      finishLoader(EVENT_LOADER_KEY)

    })
  }
  useEffect(() => {
    componentDidMount()
  }, [params.publisher, params.game, params.event])
  useEffect(() => {
    componentDidMount()
  }, [])
  const classes = useStyles()
  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h2">Event Information</Typography>
          {
            event.name && (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      Display Name
                    </TableCell>
                    <TableCell>
                      {event.display_name}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Publisher
                    </TableCell>
                    <TableCell>
                      <Link component={RouterLink} target="_blank" to={`/users/edit/${event.game.publisher.username}`}><Fullname username={event.game.publisher.username} /></Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Event Time
                    </TableCell>
                    <TableCell>
                      {
                        [
                          moment(event.started_at).format('hh:mm dddd DD MM, YYYY'),
                          moment(event.ended_at).format('hh:mm dddd DD MM, YYYY')
                        ].join(' - ')
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Description
                    </TableCell>
                    <TableCell>
                      {event.description}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Status
                    </TableCell>
                    <TableCell>
                      {event.status} {event.status === 'PENDING' && 'Waiting approval'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Pricing
                    </TableCell>
                    <TableCell>
                      {!event.extras.price && 'Free' || 'Paid'} {event.extras.price && `(Rp${event.extras.price})`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Prize
                    </TableCell>
                    <TableCell>
                      {event.extras.prize} Poin
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Poster
                    </TableCell>
                    <TableCell>
                      <div
                        style={{
                          height: 100,
                          width: 100
                        }}
                      >
                        <Gallery images={[{ src: event.poster, thumbnail: event.poster, height: 200 }]} />
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>

                    </TableCell>
                    <TableCell>
                      <Button onClick={() => setProcessOpen(true)}>Process</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )
          }
        </CardContent>
        {event && <Form event={event} open={processOpen} onDone={() => {
          setProcessOpen(false)
          getEvent()
        }} />}
      </Card>
      {
        event && event.started_at && event.started_at.getTime() > (new Date()).getTime() && <Scores event={event} />
      }

    </>
  )
}