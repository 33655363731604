import Request from '../request'
import qs from 'query-string'
const req = new Request({
  baseURL: '/backend/coupons'
})

export const coupon = {
  fetch(limit, offset){
    return req.get(`?${qs.stringify({limit, offset})}`)
  },
  async put(creator, coupon) {
    let { code } = coupon
    return !code ? req.post(`/`, coupon) : req.put(`/creator/${creator}/code/${code}`, coupon)
  },
  get(creator, code) {
    return req.get(`/creator/${creator}/code/${code}`)
  },
  deactive(creator, code) {
    return req.get(`/creator/${creator}/code/${code}/deactivate`)
  },
  active(creator, code) {
    return req.get(`/creator/${creator}/code/${code}/activate`)
  },
}

export default coupon