import Request from '../request'
import qs from 'query-string'
const req = new Request({
  baseURL: '/backend/games'
})

export const games = {
  releases(publisher, name) {
    return req.get(`/publishers/${publisher}/games/${name}/releases`)
  },
  comments(publisher, name, version) {
    return req.get(`/publishers/${publisher}/games/${name}/releases/${version}/comments`)
  },
  submitRelease(publisher, name, version, status, comment) {
    return req.put(`/publishers/${publisher}/games/${name}/releases/${version}`, {
      status,
      comment: `${comment} (${status})`
    })
  },
  games(publisher, name) {
    if (publisher) return this.gameDetail(publisher, name)
    return req.get(`/`)
  },
  getAll(page, size) {
    let p = {
      page,
      size
    }
    return req.get(`?${qs.stringify(p)}`)
  },
  gameDetail(publisher, name) {
    return req.get(`/publishers/${publisher}/games/${name}`)
  },
  links(publisher, name) {
    return req.get(`/publishers/${publisher}/games/${name}/links`)
  },
  plans(publisher, name) {
    return req.get(`/publishers/${publisher}/games/${name}/plans`)
  },
  getScores(publisher, name, page, size) {
    return req.get(`/publishers/${publisher}/games/${name}/scores?page=${page}&size=${size}`)
  },
  clearMyScores(publisher, name) {
    return req.del(`/publishers/${publisher}/games/${name}/scores`)
  },
}

export default games