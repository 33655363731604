import { useEffect, useState } from "react"
import request from "../request"

const cRequest = new request()

const usePublisherConfigService = () => {
  const [loading, setLoading] = useState(false)
  const [configuration, setConfiguration] = useState({
    register_price: 0,
    register_upoint_name: 'upoin_item_name',
    minimum_withdrawal_balance: 0,
    minimum_playstore_price: 0,
    publisher_activation_platform_fee: 0
  })
  function get(){
    setLoading(true)
    cRequest.get(`/backend/publishers/configuration`)
    .then(config => setConfiguration(config))
    .catch(error => {})
    .finally(() => setLoading(false))  
  }
  function update(cb){
    setLoading(true)
    cRequest.put(`/backend/publishers/configuration`, configuration)
    .then(() => {
      cb && cb()
    })
    .catch(error => {})
    .finally(() => setLoading(false))  
  }
  useEffect(() => {
    get()
  }, [])
  return ({
    configuration,
    setConfiguration,
    loading,
    get,
    update
  })
}

export default usePublisherConfigService
