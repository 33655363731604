import React, { useState, useEffect } from 'react';
import { makeStyles, DefaultTheme } from '@material-ui/styles';
import { Toolbar, Table } from './components';
import { Loader } from 'services/context'
import OpenIDClient from 'services/openid-client'

/**
 * 
 * @param {DefaultTheme} theme 
 */
const useStyle = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))


const List = () => {
  const [state, setState] = useState({
    limit: 10000,
    offset: 0,
    clients: []
  })
  function componentDidMount() {
    fetchClient()
  }

  function fetchClient() {
    startLoader('getClient', 'Getting client list data')
    let { limit, offset } = state
    OpenIDClient.fetch({
      limit, offset
    }).then(clients => {
      setState({ ...state, clients })
    }).catch().finally(() => {
      finishLoader('getClient')
    })
  }
  function deleteClient(client) {
    if (globalLoader.deleteClient) return
    startLoader('deleteClient', 'Deleting client ' + client.client_id)
    OpenIDClient.delete(client.client_id).then(() => fetchClient()).catch().finally(() => {
      finishLoader('deleteClient')
    })
  }
  const { startLoader, finishLoader, globalLoader } = Loader('globalLoader')

  const classes = useStyle()
  useEffect(() => {
    componentDidMount()
  }, [])

  return (
    <div className={classes.root}>
      <Toolbar onSearchChanged={(searchValue) => setState({ searchValue })} />
      <div className={classes.content}>
        <Table clients={state.clients} deleteClient={deleteClient} />
      </div>
    </div>
  );

}


export default List;
