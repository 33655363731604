import Request from '../request'
import Cookie from 'react-cookies'
import qs from 'query-string'
import { useEffect, useState } from 'react'
import { Loader } from 'services/context'

const userRequest = new Request()

const userService = {
  /**
   * 
   * @param {object} param0 
   * @param {object} param0 
   * @param {object} param0 
   * @returns {User}
   */
  async get({
    size, page, search
  }) {
    let prop = { size, page, search }
    prop = qs.stringify(prop)
    let users = await userRequest.get(`/backend/users?${prop}`)
    return {
      ...users,
      page: parseInt(users.page),
      size_per_page: parseInt(users.size_per_page),
    }
  },

  /**
   * 
   * @param {object} param0 
   * @param {object} param0 
   * @param {object} param0 
   * @returns {User}
   */
  async getUser(username) {
    return await userRequest.get(`/backend/users/${username}`)
  },
  /**
   * 
   * @param {string} username 
   * @param {User} userdata 
   */
  async put(username, userdata) {
    if (userdata.password === '') userdata.password = undefined
    return await userRequest.put(`/backend/users/${username}`, userdata)
  },
  async loggedIn() {
    return (!(!Cookie.load('sid')))
  },
  async getMe() {
    return await userRequest.get('/backend/users/me')
  },
  async usernameExists(username) {
    return await userRequest.get(`/backend/users/exists/username/${username}`)
  },
  async emailExists(email) {
    return await userRequest.get(`/backend/users/exists/email/${email}`)
  },
  async delete(username) {
    return await userRequest.del(`/backend/users/${username}`)
  }
}

export default userService

export const useUserFullname = (username) => {
  const [loading, setLoading] = useState(false)
  const [fullname, setFullname] = useState('Loading...')
  const get = () => {
    setLoading(true)
    userService.getUser(username)
      .then(u => {
        setFullname(u.info.fullname)
      })
      .catch(() => {
        setFullname(username)
      })
      .finally(() => {
        setTimeout(() => setLoading(false), 1000)
      })
  }
  useEffect(get, [])
  useEffect(get, [username])

  return {
    fullname,
    get,
    loading
  }
}
