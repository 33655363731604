
import React, { useEffect } from 'react'
import { makeStyles, Card, CardContent, Typography, TextField, Select, MenuItem, FormControl, OutlinedInput, InputLabel, IconButton, InputAdornment } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx';
import { useState } from 'react';
import { Loader } from 'services/context'
import { JWAs as Alghoritms } from 'services/openid-client'
// import Client from 'services/client'

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(2)
  },
  select: {
    width: '100%'
  }
}))
const Methods = props => {
  const { startLoader, finishLoader, globalLoader } = Loader('globalLoader')
  const { client, isEdit, validate } = props
  const [logoutChannelType, setLogoutChannelType] = useState('none')
  const classes = useStyles()
  const [values, setValues] = useState(
    /**
     * @type {Client}
     */
    client
  )
  const JWAs = Alghoritms
  const [errors, setErrors] = useState({
    clientname: '',
    email: ''
  })

  const onClientChange = client => props.onClientChange && props.onClientChange(client)

  const handleChange = prop => (event, value) => {
    
    let client = { ...values, [prop]: event.target.value ? event.target.value : value }
    setValues({ ...client }); 
    
    onClientChange(client)
  }

  const handleSeperated = (prop, separator) => event => {
    let client = { ...values, [prop]: event.target.value !== '' ? event.target.value.split(separator) : [] }
    setValues({ ...client });
    onClientChange(client)
  }

  useEffect(() => {
    setValues(client)
  }, [client])

  const logoutChannelTypes = ['none', 'frontchannel', 'backchannel']
  const logoutChannelTypeChange = event => {
    const { target: { value } } = event
    setLogoutChannelType(value)
    let client = values
    client.backchannel_logout_session_required = false
    client.frontchannel_logout_session_required = false
    if (value === 'none' || !value) {
      setValues( client )
      return
    }
    setValues({
        ...client,
        [`${value}_logout_session_required`]: true
    })
  }
  const tokenEndpointAuthMethods = ['client_secret_basic', 'client_secret_post', 'private_key_jwt']
  return (
    <Card>
      <CardContent>
        <Typography >Methods</Typography>
        <br />
        <TextField
          className={classes.margin}
          multiline
          label="Redirect uris"
          variant="outlined"
          fullWidth
          rowsMax={4}
          rows={4}
          value={values.redirect_uris && values.redirect_uris.join('\n')}
          onChange={handleSeperated('redirect_uris', '\n')}
          helperText="*Enter seperated"
        />

        <TextField
          helperText="*Enter seperated"

          className={classes.margin}
          multiline
          label="Request uris"
          variant="outlined"
          fullWidth
          rowsMax={4}
          rows={4}
          value={values.request_uris && values.request_uris.join('\n')}
          onChange={handleSeperated('request_uris', '\n')}
        />

        <FormControl className={clsx(classes.margin, classes.select)} variant="outlined">
          <InputLabel id="requesturi-alg-simple-select-outlined-label">Request object signing</InputLabel>
          <Select
            label="Request object signing"
            labelId="requesturi-alg-simple-select-outlined-label"
            id="requesturi-alg-simple-select-outlined"
            value={values.request_object_signing_alg || JWAs[0]}
            onChange={handleChange('request_object_signing_alg')}
            defaultValue={JWAs[0]}
          >
            {
              JWAs.map(v => (
                <MenuItem key={v} value={v}>{v}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        <TextField
          helperText="*Enter seperated"
          className={classes.margin}
          multiline
          label="Post Logout Redirect Uris"
          variant="outlined"
          fullWidth
          rowsMax={4}
          rows={4}
          value={values.post_logout_redirect_uris.join('\n')}
          onChange={handleSeperated('post_logout_redirect_uris', '\n')}
        />
        <FormControl className={clsx(classes.margin, classes.select)} variant="outlined">
          <InputLabel id="logout-channel-type-simple-select-outlined-label">Logout Channel</InputLabel>
          <Select
            label="Logout channel"
            labelId="logout-channel-type-simple-select-outlined-label"
            id="logout-channel-type-simple-select-outlined"
            value={logoutChannelType || logoutChannelTypes[0]}
            onChange={logoutChannelTypeChange}
            defaultValue={logoutChannelTypes[0]}
          >
            {
              logoutChannelTypes.map(v => (
                <MenuItem key={v} value={v}>{v}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
        {
          (values.logoutChannelType === 'none') || (
            <TextField
              className={classes.margin}
              label="Logout channel URI"
              variant="outlined"
              fullWidth
              value={values[`${values.logoutChannelType}_logout_uri`]}
              onChange={handleChange(`${values.logoutChannelType}_logout_uri`)}
            />
          )
        }

        <FormControl className={clsx(classes.margin, classes.select)} variant="outlined">
          <InputLabel id="token-auth-method-simple-select-outlined-label">Token endpoint auth method</InputLabel>
          <Select
            label="Token endpoint auth method"
            labelId="token-auth-method-simple-select-outlined-label"
            id="token-auth-method-simple-select-outlined"
            value={values.token_endpoint_auth_method || tokenEndpointAuthMethods[0]}
            onChange={handleChange('token_endpoint_auth_method')}
            defaultValue={tokenEndpointAuthMethods[0]}
          >
            {
              tokenEndpointAuthMethods.map(v => (
                <MenuItem key={v} value={v}>{v}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  )
}

export default Methods