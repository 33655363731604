import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import App from './App';
import {GlobalStateProvider} from './services/context'
const render = (Component) => {
    ReactDOM.render(<GlobalStateProvider><Component /></GlobalStateProvider>, document.getElementById('root'))
  };
  
render(App);

serviceWorker.unregister();

if (module.hot) {
    module.hot.accept('./App', () => {
      // if you are using harmony modules ({modules:false})
      render(App);
    });
  }
  