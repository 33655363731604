import Request from '../request'
const clientRequest = new Request()

export const JWAs = ['none', 'HS256', 'HS384', 'HS512', 'RS256', 'RS384', 'RS512', 'ES256', 'ES384', 'ES512',
  'PS256', 'PS384', 'PS512', 'RSA1_5', 'RSA-OAEP', 'RSA-OAEP-256', 'A128KW', 'A192KW',
  'A256KW', 'dir', 'ECDH-ES', 'ECDH-ES+A128KW', 'ECDH-ES+A192KW', 'ECDH-ES+A256KW', 'A128GCMKW',
  'A192GCMKW', 'A256GCMKW', 'PBES2-HS256+A128KW', 'PBES2-HS384+A192KW', 'PBES2-HS512+A256KW', 'EdDSA',
  'RSA-OAEP-384', 'RSA-OAEP-512']

export const Def = () => ({
  'allowed_cors_origins': [],
  'audience': [],
  'backchannel_logout_session_required': true,
  'backchannel_logout_uri': '',
  'client_id': '',
  'client_name': '',
  'client_secret': '',
  'client_secret_expires_at': 0,
  'client_uri': '',
  'contacts': [],
  'created_at': '2020-04-06T12:34:42Z',
  'frontchannel_logout_session_required': true,
  'frontchannel_logout_uri': '',
  'grant_types': [],
  'jwks': {},
  'jwks_uri': '',
  'logo_uri': '',
  'metadata': {},
  'owner': '',
  'policy_uri': '',
  'post_logout_redirect_uris': [],
  'redirect_uris': [],
  'request_object_signing_alg': '',
  'request_uris': [],
  'response_types': [],
  'scope': '',
  'sector_identifier_uri': '',
  'subject_type': '',
  'token_endpoint_auth_method': '',
  'tos_uri': '',
  'updated_at': '2020-04-06T12:34:42Z',
  'userinfo_signed_response_alg': ''
})

export default {
  /**
   * 
   * @param {Client} client 
   * @returns {Promise<Client>}
   */
  add(client) {
    return clientRequest.post('/backend/openid/clients', client)
  },
  /**
   * 
   * @param {Client} client 
   * @param {string} clientId
   * @returns {Promise<Client>}
   */
  edit(clientId, client) {
    return clientRequest.put(`/backend/openid/clients/${clientId}`, client)
  },
  /**
   * @returns {Promise<Client[]>}
   */
  fetch() {
    return clientRequest.get(`/backend/openid/clients`)
  },
  /**
   * @param {string} clientId 
   * @returns {Promise<Client>}
   */
  get(clientId) {
    return clientRequest.get(`/backend/openid/clients/${clientId}`)
  },
  /**
   * 
   * @param {string} clientId 
   * @returns {Promise<void>}
   */
  delete(clientId) {
    return clientRequest.del(`/backend/openid/clients/${clientId}`)
  }
}