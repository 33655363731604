
import React, { useEffect } from 'react'
import { makeStyles, Card, CardContent, Typography, TextField, Select, MenuItem, FormControl, OutlinedInput, InputLabel, IconButton, InputAdornment } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx';
import { useState } from 'react';
import { Loader } from 'services/context'
// import Client from 'services/client'

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(2)
  },
  select: {
    width: '100%'
  }
}))
const AccountInfo = props => {
  const { client, isEdit } = props
  const classes = useStyles()
  const [values, setValues] = useState(
    /**
     * @type {Client}
    */
    client
  )

  const [errors, setErrors] = useState({
    clientname: '',
    email: ''
  })

  const onClientChange = client => props.onClientChange && props.onClientChange(client)

  const handleChange = prop => (event, value) => {
    let client = { ...values, [prop]: event.target.value ? event.target.value : value }
    setValues({ ...values, ...client });

    onClientChange(client)
    validateAction[prop] && validateAction[prop](event.target.value)
  }

  useEffect(() => {
    setValues({ ...client })

  }, [client])
  const validateAction = {
    // clientname: isClientnameExists,
    // email: isEmailExists
  }
  const grantTypes = [
    "authorization_code",
    "refresh_token",
    "password",
    "client_credentials",
    "implicit"
  ]

  const responseTypes = [
    'code', 'id_token', 'token'
  ]

  const tokenEndpointAuthMethods = [
    'client_secret_post', 'client_secret_basic', 'private_key_jwt', 'none'
  ]

  const subjectTypes = [
    'public', 'pairwise'
  ]
  return (
    <Card>
      <CardContent>
        <Typography >Client Application Info</Typography>
        <br />
        {isEdit &&
          (
            <>
              <TextField
                className={classes.margin}
                label="Client ID"
                variant="outlined"
                disabled={true}
                fullWidth
                value={values.client_id}
              />
              <br/>
              <br/>
              * save client secret in the secured place, this will be not shown in the future
              <TextField
                className={classes.margin}
                label="Client Secret"
                variant="outlined"
                disabled={true}
                fullWidth
                value={values.client_secret}
              />
            </>
          )
        }
        <TextField
          error={errors.client_name}
          helperText={errors.client_name}
          className={classes.margin}
          label="Client Name"
          variant="outlined"
          fullWidth
          value={values.client_name}
          onChange={handleChange('client_name')}
        />
        <TextField
          className={classes.margin}
          label="Client Website"
          variant="outlined"
          fullWidth
          value={values.client_uri}
          onChange={handleChange('client_uri')}
        />
        <TextField
          className={classes.margin}
          label="Logo URI"
          variant="outlined"
          fullWidth
          value={values.logo_uri}
          onChange={handleChange('logo_uri')}
        />

        <TextField
          className={classes.margin}
          label="Policy URI"
          variant="outlined"
          fullWidth
          value={values.policy_uri}
          onChange={handleChange('policy_uri')}
        />
        <TextField
          className={classes.margin}
          label="TOS URI"
          variant="outlined"
          fullWidth
          value={values.tos_uri}
          onChange={handleChange('tos_uri')}
        />

        <TextField
          className={classes.margin}
          multiline
          label="Scopes"
          variant="outlined"
          fullWidth
          rowsMax={4}
          rows={4}
          value={values.scopes}
          onChange={handleChange('scopes')}
        />
        <span>*comma seperated</span>
        <Autocomplete
          className={`${classes.margin} ${classes.select}`}
          multiple
          id="tags-outlined"
          options={grantTypes}
          getOptionLabel={(option) => option}
          defaultValue={[grantTypes[0]]}
          filterSelectedOptions
          onChange={handleChange('grantTypes')}
          value={values.grantTypes}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Grant Types"
              fullWidth
            />
          )}
        />

        <Autocomplete
          className={`${classes.margin} ${classes.select}`}
          multiple
          id="tags-outlined-res"
          options={responseTypes}
          getOptionLabel={(option) => option}
          defaultValue={[responseTypes[0]]}
          filterSelectedOptions
          onChange={handleChange('response_types')}
          value={values.response_types}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Response Types"
              fullWidth
            />
          )}
        />
        <FormControl className={clsx(classes.margin, classes.select)} variant="outlined">
          <InputLabel id="subject-type-simple-select-outlined-label">Subject Type</InputLabel>
          <Select
            label="Subject Type"
            labelId="subject-type-simple-select-outlined-label"
            id="subject-types-simple-select-outlined"
            defaultValue={subjectTypes[0]}
            value={values.subject_type || subjectTypes[0]}
            onChange={handleChange('subject_type')}
          >
            {
              subjectTypes.map(v => (
                <MenuItem key={v} value={v}>{v}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  )
}

export default AccountInfo