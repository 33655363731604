import { useEffect, useState } from "react"
import Request from "../request"

const feeRequest = new Request()

const useFeeService = (publisher) => {
  const [fee, setFee] = useState({
    game_fee: {
      value: 0,
      operator: '=',
      affected_by_coupon: false
    },
    event_fee: {
      value: 0,
      operator: '=',
      affected_by_coupon: false
    }
    
  })
  const [loading, setLoading] = useState(false)

  function get(){
    setLoading(true)
    feeRequest
    .get(`/backend/publishers/${publisher}/fee`)
    .then(fee => {
      if(fee.event_fee) {
        if(fee.event_fee.operator === '*'){
          fee.event_fee.value = fee.event_fee.value * 100
        }
      }
      if(fee.game_fee) {
        if(fee.game_fee.operator === '*'){
          fee.game_fee.value = fee.game_fee.value * 100
        }
      }
      setFee(fee)
    })
    .catch(error => {})
    .finally(() => setLoading(false))  
  }
  function update(cb){
    let _fee = JSON.parse(JSON.stringify(fee))
    if(_fee.event_fee) {
      if(_fee.event_fee.operator === '*'){
        _fee.event_fee.value = _fee.event_fee.value / 100
      }
    }
    if(_fee.game_fee) {
      if(_fee.game_fee.operator === '*'){
        _fee.game_fee.value = _fee.game_fee.value / 100
      }
    }
    setLoading(true)
    feeRequest
    .put(`/backend/publishers/${publisher}/fee`, _fee)  
    .then(() => {
      cb && cb()
    })
    .catch(error => {})
    .finally(() => setLoading(false))  
  }
  useEffect(() => {
    if(publisher) get()
  }, [publisher])
  return ({
    fee,
    setFee,
    loading,
    get,
    update
  })
  
}

export default useFeeService