/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { makeStyles, Card, CardContent, TableCell, Table, TableBody, TableRow, Link, Typography } from '@material-ui/core'
import { useEffect } from 'react'
import { useParams, Link as RouterLink } from 'react-router-dom'
import GameService, { Game } from 'services/games'
import { Loader } from 'services/context'
import { useState } from 'react'
import { GAME_LOADER_KEY } from '../list'
import Image from 'material-ui-image'
import ReactPlayer from 'react-player/lazy'
import { Fullname } from 'components/Fullname'
import Gallery from 'react-grid-gallery';
const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2)
  },
  content: {
    padding: 0
  }
}))
/**
 * @type {Game}
 */
const GAME = {}

export default props => {
  const {
    startLoader,
    finishLoader,
    globalLoader
  } = Loader('globalLoader')
  const [game, setGame] = useState(GAME)
  const [images, setImages] = useState([])
  const params = useParams()
  const componentDidMount = () => {
    getGame()
  }
  const getAgeCaption = (a) => {
    return a <= 7 ? 'Anak-anak' : a <= 12 ? 'Remaja' : 'Dewasa'
  }
  const getGame = () => {
    startLoader(GAME_LOADER_KEY, "Fetching game")
    GameService.games(params.publisher, params.game).then(g => {
      setGame(g)
    }).catch(e => {

    }).finally(() => {
      finishLoader(GAME_LOADER_KEY)

    })
  }
  useEffect(() => {
    componentDidMount()
  }, [])
  useEffect(() => {
    componentDidMount()
  }, [params.publisher, params.game])
  useEffect(() => {
    const _images = game && game.highlights && game.highlights.map((v) => ({
      src: v,
      thumbnail: v,
      height: 200
    })) || []
    setImages(_images)
  }, [game])

  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h2">Game Information</Typography>
        {
          game.name && (
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    Display Name
                  </TableCell>
                  <TableCell>
                    {game.display_name}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Publisher
                  </TableCell>
                  <TableCell>
                    <Link component={RouterLink} target="_blank" to={`/users/edit/${game.publisher.username}`}><Fullname username={game.publisher.username} /></Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Description
                  </TableCell>
                  <TableCell>
                    {game.description}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Tagline
                  </TableCell>
                  <TableCell>
                    {game.tagline}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Min age
                  </TableCell>
                  <TableCell>
                    {game.minimum_age}+ ({getAgeCaption(game.minimum_age)})
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Platform
                  </TableCell>
                  <TableCell>
                    {game.platforms.join(', ')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Tags
                  </TableCell>
                  <TableCell>
                    {game.tags.join(', ')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Categories
                  </TableCell>
                  <TableCell>
                    {game.categories.join(', ')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Pricing
                  </TableCell>
                  <TableCell>
                    {game.pricing_type} {game.pricing_type === 'paid' && game.extras.price && `(Rp${game.extras.price})`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Icon
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        height: 100,
                        width: 100
                      }}
                    >
                      <Image src={game.icon} />
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Header
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        height: 100,
                        width: 100
                      }}
                    >
                    <Gallery images={[{ src: game.header,
      thumbnail: game.header, height:200 }]} />
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Highlights
                  </TableCell>
                  <TableCell>
                    <Gallery images={images} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Videos</TableCell>
                  <TableCell>
                    {
                      game.links && game.links.map((v, i) => {
                        return v.type === 'video'
                          && (
                            <ReactPlayer url={v.url} />
                          )
                      }
                      )


                    }
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )
        }
      </CardContent>
    </Card>
  )
}