import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import Policy from 'services/policy'
import { Loader } from 'services/context'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const RBAC = (props) => {
  const classes = useStyles();

  const username = props.username
  const { startLoader, finishLoader, globalLoader } = Loader('globalLoader')

  const [userRoles, setUserRoles] = useState([])
  const [roles, setRoles] = useState([])
  function init() {
    fetchRoles()
    fetchUserRoles()
  }
  function fetchRoles() {
    startLoader('getRole', 'Getting roles')
    Policy.getRoles().then(acls => {

      let roles = []
      for (let i = 0; i < acls.length; i++) {
        const acl = acls[i]
        if (roles.indexOf(acl.subject) == -1)
          roles.push(acl.subject)
      }
      setRoles(roles)
    }).catch(err => {

    }).finally(() => {
      finishLoader('getRole')
    })
  }
  function fetchUserRoles() {
    startLoader('getRole', 'Getting roles')
    Policy.getSubjectRoles(username).then(userRoles => {
      setUserRoles(userRoles)
      // let roles 
    }).catch(err => {

    }).finally(() => {
      finishLoader('getRole')
    })
  }
  function handleToggle(str) {
    if (userRoles.indexOf(str) !== -1) {
      unsignRoles(str)
    } else assignRoles(str)
  }
  function unsignRoles(role) {
    startLoader('unassignRole', 'Unsigning roles from the user')
    Policy.unassignRole(username, role).then(() => {
      let ur = userRoles
      let i = ur.indexOf(role)
      delete ur[i]
      setUserRoles(ur)
    }).catch(err => {

    }).finally(() => {
      finishLoader('unassignRole')
    })
  }

  function assignRoles(role) {
    startLoader('assignRole', 'Assigning roles to the user')
    Policy.assignRole(username, role).then(() => {
      let ur = userRoles
      ur.push(role)
      setUserRoles(ur)
    }).catch(err => {

    }).finally(() => {
      finishLoader('assignRole')
    })
  }
  useEffect(() => {
    init()
  }, [username])
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <List subheader={<ListSubheader>RBAC</ListSubheader>} className={classes.root}>
            {
              roles.map((v, i) => (
                <ListItem key={`list-${i}`}>
                  <ListItemText id={`switch-list-label-${i}`} primary={v} />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={() => handleToggle(v)}
                      checked={userRoles.indexOf(v) !== -1}
                      inputProps={{ 'aria-labelledby': `switch-list-label-${i}` }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>

              ))
            }
          </List>
        </Grid>
      </Grid>
    </div>
  )
}

export default RBAC