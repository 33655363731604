/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { CardContent, CardActions, TablePagination, Table, TableHead, TableRow, TableCell, TableBody, Card, Link, Button } from "@material-ui/core"
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import GameService, { GameItem } from 'services/games'
import { useEffect } from 'react';
import { Loader } from 'services/context';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom'
import { Fullname } from 'components/Fullname';
export const GAME_LOADER_KEY = 'game'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2)
  },
  content: {
    padding: 0
  }
}))
/**
 * @type {GameItem[]}
 */
const GAMES = []
export default (props) => {
  const {
    startLoader,
    finishLoader,
    globalLoader
  } = Loader('globalLoader')

  const [games, setGames] = useState(
    GAMES
  )

  const componentDidMount = () => {
    getGames()
  }
  const [[page, size], setPage] = useState(
    [1, 10]
  )
  const [totalPages, setTotalPages] = useState(
    1
  )
  const [totalRows, setTotalRows] = useState(
    0
  )

  const getGames = () => {
    startLoader(GAME_LOADER_KEY, 'Fetching games')
    GameService.getAll(page, size).then(games => {
      setGames(games.games)
      setTotalRows(games.total_rows)
    }).catch(e => {

    }).finally(() => {
      finishLoader(GAME_LOADER_KEY)
    })
  }

  useEffect(() => {
    componentDidMount()
  }, [])

  useEffect(() => {
    getGames()
  }, [page, size])
  const classes = useStyles()
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Publisher
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Releases
                </TableCell>
                <TableCell>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                games.map((e, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Link component={RouterLink} to={`users/edit/${e.publisher.username}`}><Fullname username={e.publisher.username} /></Link>
                    </TableCell>
                    <TableCell>
                      {e.name}
                    </TableCell>
                    <TableCell>
                      {e.release_count} releases
                    </TableCell>
                    <TableCell>
                      <RouterLink to={`publishers/${e.publisher.username}/games/${e.name}/releases`}><Button
                        color="primary"
                        variant="contained">Detail</Button></RouterLink>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </CardContent>
      <CardActions>

        <TablePagination
          component="div"
          count={totalRows}
          onChangePage={((evt, page) => setPage([page + 1, size]))}
          onChangeRowsPerPage={(evt, size) => {
            setPage([1, size.props.value])
          }}
          page={page - 1}
          rowsPerPage={size}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  )
}