
import React, {useEffect} from 'react'
import { makeStyles, Card, CardContent, Typography, TextField, Grid, FormControl, OutlinedInput, InputLabel, IconButton, InputAdornment } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';
import { useState } from 'react';

const useStyles = makeStyles( theme => ({
  margin: {
    marginTop: theme.spacing(2)
  }
}))
const ProfileAddress = props => {
  const { address, validate } = props
  const classes = useStyles()
  const [values, setValues] = useState({
    address
  })
  const onAddressChange = address => props.onAddressChange && props.onAddressChange(address)

  const handleChange = prop => event => {
    let address = {...values.address, [prop]: event.target.value }
    setValues({ ...values, address });
    onAddressChange(address)
  }
  useEffect(() => {
    let { address } = props
    setValues({...values, address })
  }, [address])

  return (
    <Card>
      <CardContent>
        <Typography >Account Info</Typography>
        <br/>
        <TextField
        className={classes.margin}
          label="Country"
          variant="outlined"
          fullWidth
          value={values.address && values.address.locality}
          onChange={handleChange('locality')}

        />
        <TextField
        className={classes.margin}
          label="Province"
          variant="outlined"
          value={values.address && values.address.state}
          onChange={handleChange('state')}
          fullWidth
        />
        <TextField
        className={classes.margin}
          label="City"
          variant="outlined"
          value={values.address && values.address.region}
          onChange={handleChange('region')}
          fullWidth
        />
        <TextField
        className={classes.margin}
          label="Postal Code"
          variant="outlined"
          value={values.address && values.address.postal_code}
          onChange={handleChange('postal_code')}
          fullWidth
        />
        <TextField
          className={classes.margin}
          label="Street address"
          variant="outlined"
          multiline
          rows="4"
          value={values.address && values.address.street_address}
          onChange={handleChange('street_address')}
          fullWidth
        />
      </CardContent>
    </Card>
  )
}

export default ProfileAddress