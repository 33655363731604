
import React, { useEffect } from 'react'
import { makeStyles, Card, CardContent, Typography, TextField, Select, MenuItem, FormControl, OutlinedInput, InputLabel, IconButton, InputAdornment } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx';
import { useState } from 'react';
import { Loader } from 'services/context'
import { JWAs as Alghoritms } from 'services/openid-client'
// import Client from 'services/client'

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(2)
  },
  select: {
    width: '100%'
  }
}))
const AuthDomain = props => {
  const { startLoader, finishLoader, globalLoader } = Loader('globalLoader')
  const { client, isEdit, validate } = props
  const classes = useStyles()
  const [values, setValues] = useState(
    client
  )
  const JWAs = Alghoritms
  const [errors, setErrors] = useState({
    clientname: '',
    email: ''
  })

  const onClientChange = client => props.onClientChange && props.onClientChange(client)


  const handleSeperated = (prop, separator) => event => {
    let client = { ...values, [prop]: event.target.value !== '' ? event.target.value.split(separator) : [] }
    setValues({ ...client });
    onClientChange(client)
  }

  useEffect(() => {
    let { client } = props
    setValues({ ...client })
  }, [client])
  return (
    <Card>
      <CardContent>
        <Typography >Auth Domains</Typography>
        <br />
        <TextField
          className={classes.margin}
          multiline
          label="Audience domains"
          variant="outlined"
          fullWidth
          rowsMax={4}
          rows={4}
          value={values.audience && values.audience.join('\n')}
          onChange={handleSeperated('audience', '\n')}
          helperText="*Enter seperated"
        />

        <TextField
          helperText="*Enter seperated"
          className={classes.margin}
          multiline
          label="Allowed cors origins"
          variant="outlined"
          fullWidth
          rowsMax={4}
          rows={4}
          value={values.allowed_cors_origins && values.allowed_cors_origins.join('\n')}
          onChange={handleSeperated('allowed_cors_origins', '\n')}
        />
      </CardContent>
    </Card>
  )
}

export default AuthDomain