import PublisherFee from '../../components/Publisher/PublisherFee'
import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import PublisherConfiguration from '../../components/Publisher/Configuration'
const useStyle = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

const PublisherView = () => {
  const classes = useStyle()
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography variant='h1' >Fees</Typography>
        <PublisherFee publisher={'default'}/>
        <br/>
        <Typography variant='h1' >Configuration</Typography>
        <PublisherConfiguration/>
      </div>
    </div>
  )
}

export default PublisherView