import React from 'react'
import { Tabs, Tab, makeStyles, AppBar } from '@material-ui/core'
import { UseGlobalState } from 'services/context'
const GLOBAL_KEY = 'apiTab',
  INDEX_API = 0,
  INDEX_BACKEND = 1,
  INDEX_CONSUMER = 2

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  }
}))
const Header = (props) => {
  const classes = useStyles()
  const [value, setValue] = UseGlobalState(GLOBAL_KEY)
  const handleChange = (e, v) => {
    setValue(v)
  }
  return (
    <AppBar position="static">
      <Tabs value={value || 0} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="APIs" />
        <Tab label="Backends" />
        <Tab label="Consumer" />
      </Tabs>
    </AppBar>
  )
}

export default Header
export {
  GLOBAL_KEY,
  INDEX_API,
  INDEX_BACKEND,
  INDEX_CONSUMER
}