import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import AccessControl from '../AccessControl'
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyIcon from '@material-ui/icons/Lock';

import { Link as RouterLink, withRouter } from 'react-router-dom';
import { ConfirmDialog } from 'components/Dialog'
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Button,
  Link
} from '@material-ui/core';

import { getInitials } from 'helpers';
import { UseGlobalState } from 'services/context';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  button: {
    margin: theme.spacing(1),
  }
}));

const ClientsTable = props => {
  const { className, clients, ...rest } = props;
  const classes = useStyles();

  const [selectedClients, setSelectedClients] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [dialogState, setDialogState] = useState({
    client: '', clientPos: -1, open: false
  })

  const [modalACL, setModalACL] = useState({
    open: false,
    clientname: ''
  })
  const handleSelectAll = event => {
    const { clients } = props;
    let selectedClients;

    if (event.target.checked) {
      selectedClients = clients.map(client => client.clientId);
    } else {
      selectedClients = [];
    }

    setSelectedClients(selectedClients);
  };
  const deleteClient = (pos) => {
    let client = clients[pos]
    setDialogState({
      client: client.client_name,
      open: true,
      clientPos: pos
    })
  }
  const onOkayDeleteClient = () => {
    props.deleteClient && props.deleteClient(clients[dialogState.clientPos])
    setDialogState({
      ...dialogState,
      open: false
    })
  }
  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedClients.indexOf(id);
    let newSelectedClients = [];

    if (selectedIndex === -1) {
      newSelectedClients = newSelectedClients.concat(selectedClients, id);
    } else if (selectedIndex === 0) {
      newSelectedClients = newSelectedClients.concat(selectedClients.slice(1));
    } else if (selectedIndex === selectedClients.length - 1) {
      newSelectedClients = newSelectedClients.concat(selectedClients.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedClients = newSelectedClients.concat(
        selectedClients.slice(0, selectedIndex),
        selectedClients.slice(selectedIndex + 1)
      );
    }

    setSelectedClients(newSelectedClients);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };
  const accessControl = (clientname) => {
    setModalACL({
      open: true,
      clientname
    })
  }
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <ConfirmDialog title="Delete Confirmation" open={dialogState.open} message={`Are you sure want to delete ${dialogState.client}?`} onCancel={() => setDialogState({ ...dialogState, open: false })} onOkay={onOkayDeleteClient} />
      <AccessControl open={modalACL.open} clientname={modalACL.clientname} onDone={() => { setModalACL({ ...modalACL, open: false }) }} />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedClients.length === clients.length}
                      color="primary"
                      indeterminate={
                        selectedClients.length > 0 &&
                        selectedClients.length < clients.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Client ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Scope</TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>Grant Types</TableCell>
                  <TableCell>Response Types</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clients.slice(0, rowsPerPage).map((client, index) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={client.clientId}
                    selected={selectedClients.indexOf(client.clientId) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedClients.indexOf(client.clientId) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, client.clientId)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{client.client_id}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{client.client_name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{client.scope}</TableCell>
                    <TableCell>{client.owner}</TableCell>
                    <TableCell>{client.grant_types.join(', ')}</TableCell>
                    <TableCell>{client.response_types.join(', ')}</TableCell>
                    <TableCell>

                      <Button
                        onClick={() => accessControl(client.clientname)}
                        className={classes.button}
                        startIcon={<KeyIcon />}
                      >Access Control</Button>
                      <Link component={RouterLink} to={`/openid-client/edit/${client.client_id}`}>
                        <Button
                          className={classes.button}
                          color="primary"
                          startIcon={<EditIcon />}
                        >Edit</Button>
                      </Link>
                      {/* <Button
                        onClick={() => deleteClient(index)}
                        className={classes.button}
                        startIcon={<DeleteIcon />}
                      >Delete</Button> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

ClientsTable.propTypes = {
  className: PropTypes.string,
  clients: PropTypes.array.isRequired
};

export default ClientsTable;
