/* eslint-disable react-hooks/exhaustive-deps */
import useRewardModule from '../../services/rewards'
import React, { useEffect, useState } from 'react'
import { Loader } from '../../services/context'
import { Button, ButtonGroup, Card, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Link } from 'react-router-dom'
import IconNext from '@material-ui/icons/ArrowRight'
import IconPrev from '@material-ui/icons/ArrowLeft'
import IconEdit from '@material-ui/icons/Edit'
import IconDelete from '@material-ui/icons/Remove'

import { Link as RouterLink } from 'react-router-dom'
import { ConfirmDialog } from 'components/Dialog'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    marginTop: 10
  },
  content: {
    padding: 0
  },
  buttonGroup: {
    padding: 20,
    position: "absolute",
    right: 0
  }
}))
const RewardList = () => {
  const { startLoader, finishLoader} = Loader()
  const [confirmDialogState, setConfirmDialogState] = useState({
    open: false,
    id: ''
  })
  const { 
    listReward,
    setListReward,
    fetch,
    page, setPage,
    size,
    loading: { fetchLoading, deleteLoading },
    del
   } = useRewardModule()
  
  const selectToDelete = (id) => {
    setConfirmDialogState({
      open: true,
      id
    })
  }

  const onOkayDelete = () => {
    del(confirmDialogState.id, fetch)
    setConfirmDialogState({
      open: false,
      id: ''
    })
  }

   useEffect(() => {
    fetch()
  }, [])
  
  useEffect(() => {
    fetchLoading ? startLoader('list-reward-loading', 'Fetching Rewards') : finishLoader('list-reward-loading')
    deleteLoading ? startLoader('delete-reward-loading', 'Removing Rewards') : finishLoader('delete-reward-loading')

  }, [fetchLoading, deleteLoading])
  const classes = useStyles()
  return <>
    <div className={classes.root} style={{height: 40}}>
      <RouterLink style={{
        position: 'absolute',
        right: 15,
        top: 80
      }} to={`/rewards/form`}>
        <Button
          color="primary"
          variant="contained"
        >
          Add
        </Button>
      </RouterLink>

    </div>
    <Card className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Name
            </TableCell>
            <TableCell>
              Type
            </TableCell>
            <TableCell>
              Desc
            </TableCell>
            <TableCell>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            listReward.data.map((c, i) => (
              <TableRow key={`rewards-${i}`}>
                <TableCell>
                  {c.name}
                </TableCell>
                <TableCell>
                  {c.type}
                </TableCell>
                <TableCell>
                  {c.description}
                </TableCell>
                <TableCell>
                  <RouterLink to={`/rewards/form/${c.id}`}>
                    <Button
                      color="primary"
                    >
                    <IconEdit/>
                    </Button>
                  </RouterLink>
                    <Button
                    onClick={() => selectToDelete(c.id)}
                    >
                    <IconDelete/>
                    </Button>
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      <ButtonGroup className={classes.buttonGroup} size="small" aria-label="small button group">
        {
          page !== 1 &&
          <Button onClick={() => setPage(page - 1)}>
            <IconPrev/>
          </Button>
        }
        {
          page < listReward.total_row / size &&
          <Button onClick={() => setPage(page + 1)}>
            <IconNext/>
          </Button>
        }
      </ButtonGroup>
      <ConfirmDialog
        title="Delete Confirmation" 
        open={confirmDialogState.open} 
        message={`Are you sure want to delete?`} 
        onCancel={() => setConfirmDialogState({ ...confirmDialogState, open: false })} 
        onOkay={onOkayDelete} />

    </Card>
  </>
}

export default RewardList