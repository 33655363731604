import React, { useEffect, useState } from 'react'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles';
import GameService, { Release, Comment } from 'services/games'
import { Editor } from '@tinymce/tinymce-react';

import { Loader } from 'services/context'

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, ButtonGroup, FormControl, FormHelperText, Input, Toolbar, Link, Table, TableCell, TableRow, AppBar, Typography } from '@material-ui/core'

import CommentList from './commentList';
/**
 * @type {Comment[]}
 */
const COMMENTS = []
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    width: '100%',
    height: '100%',
    boxShadow: theme.shadows[5],
  },
  ButtonDone: {
    display: 'rel',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  title: {
    color: '#fff'
  }
}));

/**
 * 
 * @param {object} props
 * @param {boolean} props.open
 * @param {string} props.username
 * @param {Release} props.release
 */
const ReleaseDetail = (props) => {

  const {
    startLoader,
    finishLoader,
    globalLoader
  } = Loader('globalLoader')

  const classes = useStyles();
  const { release, open } = props
  const [comments, setComments] = useState(COMMENTS)
  const [comment, setComment] = useState('')
  useEffect(() => {
    release.game && getComments()
  }, [release, open])

  const getComments = () => {
    startLoader('get-comments', "Getting release comments")
    GameService
      .comments(release.game.publisher.username, release.game.name, release.version)
      .then(comments => {
        setComments(comments)
        finishLoader('get-comments')
      }).catch(e => {
        finishLoader('get-comments')
      })
  }
  const onDone = (p) => {
    if (comment === '') return alert('Comment must be filled')
    startLoader('submit-release', "Submitting release")
    GameService
      .submitRelease(
        release.game.publisher.username,
        release.game.name,
        release.version, p, comment)
      .then(() => {
      })
      .catch(() => {

      }).finally(() => {
        finishLoader('submit-release')
        props.onDone && setTimeout(() => props.onDone(), 1500)
      })
  }
  return (
    <Modal open={props.open}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper} style={{
          overflowY: 'auto'
        }}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Game Release
              </Typography>
            </Toolbar>
          </AppBar>
          {
            release.game && (
              <Table>
                <TableRow>
                  <TableCell>
                    Version
                  </TableCell>
                  <TableCell>
                    {release.version}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    Description
                  </TableCell>
                  <TableCell>
                    {release.description}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>

                  </TableCell>
                  <TableCell>
                    <Link
                      href={`/backend/games/publishers/${release.game.publisher.username}/games/${release.game.name}/releases/${release.version}/play/index.html`} target="__blank">
                      Play
                    </Link>
                  </TableCell>
                </TableRow>
              </Table>
            )
          }
          <Typography variant="h4" style={{ padding: '20px' }}>Comments</Typography>
          <div style={{
            height: '50%',
            overflowY: 'scroll',
            background: '#f0f0f0'
          }}>
            <CommentList comments={comments} />
          </div>
          <div className={classes.root}>
            {/* <FormControl>
              <InputLabel htmlFor="my-input">Comment</InputLabel>
              <Input value={comment} onChange={(v) => { setComment(v.target.value) }} id="my-input" aria-describedby="my-helper-text" />
              <FormHelperText id="my-helper-text">Put whatever you want to .</FormHelperText>
            </FormControl> */}
            <Editor
              initialValue="<p>Comment here</p>"
              init={{
                height: 200,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help'
              }}
              onEditorChange={(c) => { setComment(c) }}
            />
            <ButtonGroup style={{
            }} color="primary" aria-label="outlined primary button group">

              {
                release.status !== 'ACTIVE' && (
                  <Button className={classes.ButtonDone} onClick={() => onDone('ACTIVE')}>
                    Publish
                  </Button>
                )
              }
              {
                release.status === 'ACTIVE' && (
                  <Button className={classes.ButtonDone} onClick={() => onDone('INACTIVE')}>
                    Deactive
                  </Button>
                )
              }
              {
                release.status === 'PENDING' && (
                  <Button className={classes.ButtonDone} onClick={() => onDone('REJECTED')}>
                    Reject
                  </Button>
                )
              }
              <Button className={classes.ButtonDone} onClick={() => props.onDone && props.onDone()}>
                Cancel
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default ReleaseDetail