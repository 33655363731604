import React from 'react'
import { Table, TableHead, TableCell, TableRow, TableBody, Paper, Button, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import services from 'services/api'
import { useEffect } from 'react'
import { Loader, UseGlobalState } from 'services/context'
import { useState } from 'react'
import { Add, Remove, Book } from '@material-ui/icons'
import { GLOBAL_KEY, INDEX_API } from '../header'
import Wrapper from '../wraper'

export const Apis = () => {
  const { startLoader, finishLoader, globalLoader } = Loader()
  const [state, setState] = useState({
    apis: {
      next: {},
      /**
        @type {Array<API>}
      */
      data: []
    }
  })
  const LOADER_KEY = 'ApiData'
  const fetchApi = () => {
    if (globalLoader[LOADER_KEY]) return
    startLoader(LOADER_KEY, 'Fetching APIs')

    services.api.get().then(apis => {
      setState({ apis })
    }).catch(e => {
      console.log(e)
    }).finally(() => {
      finishLoader(LOADER_KEY)
    })
  }
  const componentDidMount = () => {
    fetchApi()
  }
  useEffect(() => {
    componentDidMount()
  }, [])

  return (
    <Paper>
      <Link component={RouterLink} to={`/services/apis/form/create`}>
        <Button>
          <Add />
        Add API
      </Button>
      </Link>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Api Name</TableCell>
            <TableCell>Version </TableCell>
            <TableCell>Backend</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            state.apis.data.map((v, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Link component={RouterLink} to={`/services/apis/form/edit/${v.id}`}>{v.name || v.id} </Link>
                </TableCell>
                <TableCell>{v.service.id}</TableCell>
                <TableCell>
                  <Link component={RouterLink} to={`/services/apis/form/edit/${v.id}`}>
                    <Button
                      color="primary"
                    ><Book /> View
                </Button>
                  </Link>
                  <Button ><Remove /> Delete</Button>
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </Paper>
  )
}


export default () => {
  const [_, setValue] = UseGlobalState(GLOBAL_KEY)
  setValue(INDEX_API)
  return (
    <Wrapper>
      <Apis />
    </Wrapper>
  )
}