import React, { useState, useEffect } from 'react';
import { makeStyles, DefaultTheme } from '@material-ui/styles';
import { UsersToolbar, UsersTable } from './components';
import User from 'services/user'
import { Loader } from 'services/context'
/**
 * 
 * @param {DefaultTheme} theme 
 */
const useStyle = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))


const UserList = () => {
  const [search, setSearch] = useState('')
  const [state, setState] = useState({
    users: [],
    page: 1,
    total: 1,
    size: 10
  })
  function componentDidMount() {
    fetchUser(state.size, state.page)
  }

  function fetchUser(size, page, search) {
    startLoader('getUser', 'Getting user list data')
    User.get({
      size, page, search
    }).then(users => {
      setState({ users: users.data, page: users.page, size: users.size_per_page, total: users.total })
    }).catch((e) => {
      e.code === 404 && setState({ users: [] })
    }).finally(() => {
      finishLoader('getUser')
    })
  }
  function deleteUser(user) {
    if (globalLoader.deleteUser) return
    startLoader('deleteUser', 'Deleting user ' + user.username)
    User.delete(user.username).then(() => fetchUser()).catch().finally(() => {
      finishLoader('deleteUser')
    })
  }
  function onPageChanged(v) {
    fetchUser(state.size, v)
  }
  function onPageSizeChanged(target) {
    fetchUser(target.props.value, state.page)
  }

  // const { classes, ...rest } = props
  // const [users] = useState(mockData);
  // const [searchValue, setSearchValue] = useState()
  const { startLoader, finishLoader, globalLoader } = Loader('globalLoader')

  const classes = useStyle()
  useEffect(() => {
    componentDidMount()
  }, [])
  useEffect(() => {
    if (search.length > 3 || search.length === 0) fetchUser(10, 1, search)
  }, [search])
  return (
    <div className={classes.root}>
      <UsersToolbar onSearchChanged={(search) => setSearch(search)} />
      <div className={classes.content}>
        <UsersTable
          users={state.users}
          size={state.size}
          page={state.page}
          total={state.total}
          onPageChanged={onPageChanged}
          onPageSizeChanged={onPageSizeChanged}
          deleteUser={deleteUser} />
      </div>
    </div>
  );

}


export default UserList;
