/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { CardContent, Table, TableHead, TableRow, TableCell, TableBody, Card, Link, Button, CardActionArea, CardActions, TablePagination } from "@material-ui/core"
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import EventService, { Event } from 'services/events'
import { useEffect } from 'react';
import { Loader } from 'services/context';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom'
import { Fullname } from 'components/Fullname';
export const EVENT_LOADER_KEY = 'event'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2)
  },
  content: {
    padding: 0
  }
}))
/**
 * @type {Event[]}
 */
const EVENTS = []
export default (props) => {
  const {
    startLoader,
    finishLoader,
    globalLoader
  } = Loader('globalLoader')

  const [events, setEvents] = useState(
    EVENTS
  )
  const [[page, size], setPage] = useState(
    [1, 10]
  )
  const [totalPages, setTotalPages] = useState(
    1
  )
  const [totalRows, setTotalRows] = useState(
    0
  )

  const componentDidMount = () => {
    getEvents()
  }

  const getEvents = () => {
    startLoader(EVENT_LOADER_KEY, 'Fetching events')
    EventService.events(page, size).then(events => {
      setEvents(events.events)
      setTotalPages(events.total_pages)
      setTotalRows(events.total_rows)
    }).catch(e => {

    }).finally(() => {
      finishLoader(EVENT_LOADER_KEY)
    })
  }

  useEffect(() => {
    componentDidMount()
  }, [])

  useEffect(() => {
    getEvents()
  }, [page, size])
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Event Name
                </TableCell>
                <TableCell>
                  Publisher
                </TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                events.map((e, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {e.name}
                    </TableCell>
                    <TableCell>
                      <Link component={RouterLink} to={`users/edit/${e.game.publisher.username}`}><Fullname username={e.game.publisher.username} /></Link>
                    </TableCell>
                    <TableCell>
                      <Link component={RouterLink} to={`publishers/${e.game.publisher.username}/games/${e.game.name}/releases`}>{e.game.name}</Link>
                    </TableCell>
                    <TableCell>
                      <RouterLink to={`publishers/${e.game.publisher.username}/games/${e.game.name}/events/${e.name}`}><Button
                        color="primary"
                        variant="contained">Detail</Button></RouterLink>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </CardContent>
      <CardActions>
        <TablePagination
          component="div"
          count={totalRows}
          onChangePage={((evt, page) => setPage([page + 1, size]))}
          onChangeRowsPerPage={((evt, size) => setPage([1, size.props.value]))}
          page={page - 1}
          rowsPerPage={size}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  )
}