import { Loader } from '../../services/context'
import useWithdrawalModule from '../../services/withdrawals'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, makeStyles, Select, TextField, Typography } from '@material-ui/core'
import AutoComplete from '../../components/AutoComplete'
import { MenuItem } from 'material-ui'
import { useHistory, useParams } from 'react-router'
import gameServices from '../../services/games'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  card:{
    padding: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  buttonGroup: {
    padding: 20,
    position: "absolute",
    right: 0
  },
  textField: {
    marginTop: 10
  }
}))

const WithdrawalForm = () => {
  const { startLoader, finishLoader} = Loader()
  const [openType, setOpenType] = useState(false)
  const params = useParams()
  const history = useHistory()
  const { 
    withdrawal,
    balance,
    setWithdrawal,
    get,
    approve,
    decline,
    loading: { statusLoading, fetchLoading }
   } = useWithdrawalModule()
  
   const classes = useStyles()

  const goToListPage = () => {
    setTimeout(() => history.push(`/withdrawals`), 1000)
  }
  useEffect(() => {
    get(params.id)
  }, [])
  useEffect(() => {
    statusLoading ? startLoader('update-withdrawal', 'Updating Withdrawal') : finishLoader('update-withdrawal')
    fetchLoading ? startLoader('fetch-withdrawal', 'Getting Withdrawal') : finishLoader('fetch-withdrawal')
  }, [statusLoading, fetchLoading])

  return  <div className={classes.root} >
    <Typography  variant="h2">
      Withdrawal {withdrawal.publisher_username}
    </Typography>
    <Card className={classes.card}>
      <Typography variant="h3" >
        Invoice
      </Typography>
      <Typography variant="h4" >
        {withdrawal.invoice}
      </Typography>
      <br/>
      <Typography variant="h3" >
        Publisher
      </Typography>
      <Typography variant="h4" >
        {withdrawal.publisher_username}
      </Typography>
      <br/>
      <Typography variant="h3" >
        Bank
      </Typography>
      <Typography variant="h4" >
        {withdrawal.bank_name}
      </Typography>
      <br/>
      <Typography variant="h3" >
        Bank Account Name
      </Typography>
      <Typography variant="h4" >
        {withdrawal.bank_account_name}
      </Typography>
      <br/>
      <Typography variant="h3" >
        Bank Account Num
      </Typography>
      <Typography variant="h4" >
        {withdrawal.bank_account_number}
      </Typography>
      <br/>
      <Typography variant="h3" >
        Requested Amount
      </Typography>
      <Typography variant="h4" >
        {withdrawal.withdrawal_amount}
      </Typography>
      <br/>
      <Typography variant="h3" >
        Current Balance
      </Typography>
      <Typography variant="h4" >
        {balance}
      </Typography>
      <br/>
       
      <Typography variant="h3" >
        Status
      </Typography>
      <Typography variant="h4" >
        {withdrawal.status}

      </Typography>
      <br/>
      <Typography variant="h5">
      {withdrawal.status !== 'submitted' && 'This request has been processed' }
      </Typography>       
    </Card>
    
  <div style={{textAlign: 'right'}}>
    {withdrawal.status === 'submitted' && (
      <>
      <Button
      style={{
        margin: 10,
      }}
      color="primary"
      variant="contained"
      onClick={() => approve()}
    >
      Approve
    </Button>

    <Button
      style={{
        margin: 10,
      }}
      variant="contained"
      onClick={() => decline()}
    >
      Decline
    </Button>
      </>
    )}
    
    </div>
</div>
}

export default WithdrawalForm