import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Link href="/backend/statistics" target="_blank">
        Click here
        
      </Link> for statistics app
    </div>
  );
};
export default Dashboard;
