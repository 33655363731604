import React, { useEffect, useState } from 'react'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles';

import { Loader } from 'services/context'

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, ButtonGroup, FormControl, Typography, FormHelperText, Input, InputLabel, Link, Table, Toolbar, AppBar } from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react';

import EventService, { Comment } from 'services/events'
import CommentList from './commentList';
/**
 * @type {Comment[]}
 */
const COMMENTS = []
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    width: '100%',
    height: '100%',
    boxShadow: theme.shadows[5],
  },
  ButtonDone: {
    display: 'rel',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  title: {
    color: '#fff'
  }
}));
/**
 * 
 * @param {object} props
 * @param {Function} props.onDone
 * @param {boolean} props.open
 * @param {string} props.username
 * @param {import('services/events').Event} props.event
 */
const Form = (props) => {

  const {
    startLoader,
    finishLoader,
    globalLoader
  } = Loader('globalLoader')

  const classes = useStyles();
  const { event, open } = props
  const [comments, setComments] = useState(COMMENTS)
  const [comment, setComment] = useState("")
  useEffect(() => {
    event.game && getComments()
  }, [event, open])

  const getComments = () => {
    // console.log(event)
    startLoader('get-comments', "Getting event comments")
    EventService.comments(event.game.publisher.username, event.game.name, event.name, 1, 10000)
      .then(comments => {
        setComments(comments)
        finishLoader('get-comments')
      }).catch(e => {
        finishLoader('get-comments')
      })
  }
  const onDone = (p) => {
    if (comment === '') return alert('Comment must be filled')
    startLoader('submit-event', "Submitting release")
    EventService
      .updateEvent(
        event.game.publisher.username,
        event.game.name,
        event.name, p, comment)
      .then(() => {
      })
      .catch(() => {

      }).finally(() => {
        finishLoader('submit-event')
        props.onDone && props.onDone()
      })
  }
  return (
    <Modal open={props.open}
      className={classes.modal}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper} style={{
          overflowY: 'auto'
        }}>

          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Event Approval
              </Typography>
            </Toolbar>
          </AppBar>
          <Typography variant="h4" style={{ padding: '20px' }}>Comments</Typography>
          <div style={{
            height: '50%',
            overflowY: 'scroll',
            background: '#f0f0f0'
          }}>
            <CommentList comments={comments} />
          </div>
          <div className={classes.root}>
            <Editor
              initialValue="<p>Comment here</p>"
              init={{
                height: 200,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount'
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | help'
              }}
              onEditorChange={(c) => { setComment(c) }}
            />
            <ButtonGroup style={{
            }} color="primary" aria-label="outlined primary button group">

              <Button className={classes.ButtonDone} onClick={() => onDone('PUBLISHED')}>
                Publish
              </Button>
              <Button className={classes.ButtonDone} onClick={() => onDone('REJECTED')}>
                Reject
              </Button>
              <Button className={classes.ButtonDone} onClick={() => props.onDone && props.onDone()}>
                Cancel
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Fade>
    </Modal>
  )
}

export default Form