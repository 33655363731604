import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import GamesIcon from '@material-ui/icons/Games';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import CouponIcon from '@material-ui/icons/AccountBalanceWallet';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import GiftCardIcon from '@material-ui/icons/CardGiftcard';
import SettingsIcon from '@material-ui/icons/SettingsApplications';
import MoneyIcon from '@material-ui/icons/MonetizationOn';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: 'Users',
      href: '/users',
      icon: <PeopleIcon />
    },
    {
      title: 'Games',
      href: '/games',
      icon: <GamesIcon />
    },
    {
      title: 'OpenID Clients',
      href: '/openid-client',
      icon: <LockOpenIcon />
    },
    {
      title: 'Coupon',
      href: '/coupon',
      icon: <CouponIcon />
    },
    {
      title: 'Events',
      href: '/events',
      icon: <CalendarIcon />
    },
    {
      title: 'Rewards',
      href: '/rewards',
      icon: <GiftCardIcon />
    },
    {
      title: 'Publisher Config',
      href: '/publisher-portal',
      icon: <SettingsIcon />
    },
    {
      title: 'Publisher Withdrawal Requests',
      href: '/withdrawals',
      icon: <MoneyIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
        />
        {/* <UpgradePlan /> */}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
