import Request from '../request'

const req = new Request()
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  /**
   * @returns {Promise<String[]>}
   */
  async getRoles() {
    let roles = await req.get(`/backend/policies/roles`)
    return roles
  },
  /**
   * 
   * @param {string} username
   * @returns {Promise<String[]>}
   */
  async getSubjectRoles(username) {
    let roles = await req.get(`/backend/policies/subject/user:${username}/roles`)
    return roles
  },
  /**
   * @param {string} username
   * @param {string} role
   */
  async assignRole(username, role) {
    return await req.put(`/backend/policies/roles`, {
      subject: `user:${username}`,
      policy: role
    })
  },
  /**
   * @param {string} username
   * @param {string} role
   */
  async unassignRole(username, role) {
    return await req.del(`/backend/policies/subject/user:${username}/roles/${role}`)
  }
}