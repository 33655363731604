import { Dialog } from '@material-ui/core'
import React, { useEffect } from 'react'
import PublisherFee from './PublisherFee'

const PublisherFeeDialog = ({ open, publisher, onSuccess }) => {
  return <>
    <Dialog open={open}>
      <PublisherFee publisher={publisher} onSuccess={onSuccess}/>
    </Dialog>
  </>
}

export default PublisherFeeDialog