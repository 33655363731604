import React from 'react'
import { Box, Container } from "@material-ui/core"
import Header from "./header"

const Wrapper = ({children}) => {
  return (
    <Box>
      <Header/>
    <Container>
      <br/>
      {children}
    </Container>
    </Box>
  )
}

export default Wrapper