import { UseGlobalState } from 'services/context'
import { CardContent, Card, Typography, Divider } from '@material-ui/core'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import React from 'react'
import moment from 'moment';
/**
 * @param {object} prop
 * @param {import('services/games').Comment} prop.comments
 */
export default ({
  comments
}) => {
  const [globalUser] = UseGlobalState('USER')
  return (
    <div style={{
      padding: '20px'
    }}>
      {
        comments && comments.map((c, i) => (
          <div>
            <Typography style={{
            }} variant="caption">
              {c.commentator_username === globalUser.username ? 'me' : c.commentator_username}
            </Typography>
            <Typography>
              {ReactHtmlParser(c.comment)}
            </Typography>
            <Typography style={{
            }} variant="caption">
              {moment(c.created_at).format('dddd, DD MMMM YYYY HH:mm')}
            </Typography>
            <Divider />
            <br />
          </div>
        ))
      }
    </div>
  )
}
