import React, { Component, useState, useEffect } from 'react'
import clsx from 'clsx';

import Client, { Def as ClientDef } from 'services/openid-client'
import { makeStyles, Card, CardContent, Typography, TextField, Grid, FormControl, OutlinedInput, InputLabel, IconButton, InputAdornment, Button, Modal } from '@material-ui/core'
import ClientInfo from './components/ClientInfo';
import Methods from './components/Methods';
import ProfileAddress from './components/ProfileAddress';
import { useParams } from 'react-router-dom';
import { Loader } from 'services/context'
import AuthDomain from './components/AuthDomain';
import * as validUrl from 'valid-url'
/**
 * 
 * @param {DefaultTheme} theme 
 */
const useStyle = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    padding: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(2)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'right',
    marginTop: theme.spacing(1),
    flexDirection: 'column'
  },
  buttonAction: {
    display: 'flex',
    alignSelf: 'right'
  }
}))

const Form = () => {
  const classes = useStyle()

  let { id: _id } = useParams()
  const [id, setId] = useState(_id)
  const [values, setValues] = useState(
    ClientDef())
  let isMounted = false
  const mounted = () => {
    isMounted = true
    if (id) {
      startLoader('getClient', 'Getting client data')
      Client.get(id).then(client => {
        setValues(client)
      }).catch(err => { })
        .finally(() => {
          finishLoader('getClient')
        })
    }
  }
  useEffect(() => {
    !isMounted && mounted()
  }, [])
  const validate = () => {
    if(values.client_name === '') {
      alert(`client name required`)
      return false
    }

    if(values.redirect_uris.length === 0) {
      alert(`redirect uris required`)
      return false
    }

    for (const p in values.redirect_uris) {
      if(!validUrl.isHttpsUri(values.redirect_uris[p])){
        alert(`redirect uris has to be a https url`)
        return false
      }
    }

    if(values.request_uris === 0) {
      alert(`request uri required`)
      return false
    }
    
    for (const p in values.request_uris) {
      if(!validUrl.isHttpsUri(values.request_uris[p])){
        alert(`redirect uris has to be a https url`)
        return false
      }
    }
    if(values.post_logout_redirect_uris.length === 0) {
      alert(`post logout redirect uris required`)
      return false
    }
    for (const p in values.post_logout_redirect_uris) {
      if(!validUrl.isHttpsUri(values.post_logout_redirect_uris[p])){
        alert(`post logout uris has to be a https url`)
        return false
      }
    }
    if(values.audience.length === 0) {
      alert(`audience required`)
      return false
    }
    for (const p in values.audience) {
      if(!validUrl.isHttpsUri(values.audience[p])){
        alert(`audience has to be a https url`)
        return false
      }
    }
    if(values.allowed_cors_origins.length === 0) {
      alert(`cors required`)
      return false
    }
    for (const p in values.allowed_cors_origins) {
      if(!validUrl.isHttpsUri(values.allowed_cors_origins[p])){
        alert(`allowed cors origin has to be a https url`)
        return false
      }
    }
    
    return true
  }
  const { startLoader, finishLoader, globalLoader } = Loader('globalLoader')
  const handleSave = () => {
    if(!validate()) return false 
    if (globalLoader.saveClient) return
    startLoader('saveClient', 'Saving client data')
    let action = Client.add(values)
    if (id) {
      action = Client.edit(id, values)
    }
    action.then(resp => {
      setValues({
          ...resp,
        post_logout_redirect_uris: resp.post_logout_redirect_uris,
        redirect_uris: resp.redirect_uris,
        request_uris: resp.request_uris
      })
      setId(resp.client_id)
    }).catch(e => {
      e.message && e.message.error_hint && alert(e.message.error_hint)
     }).finally(() => {
      finishLoader('saveClient')
    })

  }
  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>Client {id ? `Edit ${values.client_id}` : 'Add'}</Typography>
      <Grid container>
        <Grid className={classes.content} item xs={12} sm={12} md={4}>
          <ClientInfo client={values} isEdit={id ? true : false} onClientChange={client => setValues({ ...client })} />
        </Grid>
        <Grid className={classes.content} item xs={12} sm={12} md={4}>
          <Methods client={values} onClientChange={client => setValues({ ...client })} />
        </Grid>
        <Grid className={classes.content} item xs={12} sm={12} md={4}>
          <AuthDomain client={values} onClientChange={client => setValues({ ...client })} />
        </Grid>
      </Grid>
      <div className={classes.row}>
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonAction}
          onClick={handleSave}
        >
          Save
        </Button>

      </div>
    </div>
  )
}

export default Form