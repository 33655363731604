import Request from '../request'
const req = new Request({
  baseURL: '/backend/notifications'
})

export default {
  async getNotification() {
    const notifications = await req.get('')
    return notifications
  },
  async getNextNotification(id) {
    const notifications = await req.get(`/next/${id}`)
    return notifications

  },
  async getNewestNotification(id) {
    const notifications = await req.get(`/newest/${id}`)
    return notifications

  },
  async getUnreadNotification() {
    const notifications = await req.get(`/unread`)
    return notifications.count

  },
  async readNotification(id) {
    await req.put(`/read/${id}`)
  }
}