import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import couponServices from '../../services/coupon'
import gameServices from '../../services/games'
import userServices from '../../services/user'
import { Loader } from '../..//services/context'
import { Button, Card, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, makeStyles, Select, TextField, Typography } from '@material-ui/core'
import AutoComplete from '../../components/AutoComplete'
import { MenuItem } from 'material-ui'
import moment from 'moment-timezone'
export const FORM_COUPON = 'form-coupon'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  card:{
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  buttonGroup: {
    padding: 20,
    position: "absolute",
    right: 0
  },
  textField: {
    marginTop: 10
  }
}))

export const CouponForm = () => {
  const classes = useStyles()

  const params = useParams()
  const {startLoader, finishLoader, globalLoader} = Loader()
  const history = useHistory()
  const [isExpired, setIsExpired] = useState(false)
  const [openType, setOpenType] = useState(false)
  const creator = params.creator || 'admin'
  const [coupon, setCoupon] = useState(
    {
      "code": "",
      "quota": 0,
      "value": 0,
      "type": "=",
      "conditions": {}
    }
  )
  const fetchCoupon = () => {
    if(globalLoader[FORM_COUPON]) return
    startLoader(FORM_COUPON, 'Getting coupon data')
    couponServices
    .get(params.creator, params.code)
    .then((coupon) => {
      setCoupon({
        ...coupon,
        code: params.code,
        expire: coupon.expire && moment(coupon.expire).format('YYYY-MM-DDTHH:mm'),
        value: coupon.type === '*' ? coupon.value * 100 : coupon.value ,
        conditions: coupon.conditions || {}
      })
      setIsExpired(coupon.expire ? true : false)
    })
    .catch(e => {

    })
    .finally(() => finishLoader(FORM_COUPON))
  }

  const saveCoupon = () => {
    if(coupon.type === '*' && coupon.value > 100) {
      alert(`Coupon value with type Percentage should be not more than 100`)
      return
    }
    if(!coupon.conditions.game){
      return alert('Game required')
    }
    if(globalLoader[FORM_COUPON]) return

    startLoader(FORM_COUPON, 'Saving coupon data')
    couponServices
    .put(creator, {
      ...coupon,
      value: coupon.type === '*' ? coupon.value / 100 : coupon.value ,
      expire: isExpired ? new Date(coupon.expire) : null
    })
    .then(() => 
      history.push('/coupon')
    )
    .catch(e => {
      if(typeof e.message === 'string') return alert(e.message)
      if(!e.response) alert(`Connection error, check you internet`)
      else if(typeof e.response.body.message === 'string') alert(e.response.body.message)
      else alert(`Something error, please contact system administrator`)
    })
    .finally(() => finishLoader(FORM_COUPON))
  }


  const componentDidMount = () => {
    params.code && (() => {
      setCoupon({...coupon, code: params.code})
      fetchCoupon()
    })()
  }
  useEffect(componentDidMount, [])
  const fetchGames = async (gameParams) => {
    const {games} = await gameServices.getAll(1, 10000)
    const list = [
      // {
      //   label: 'ALL'
      // }
    ]
    for (const i in games) {
      const element = games[i];
      list.push({
        label: `${element.publisher.username} - ${element.name}`,
        name: `${element.publisher.username}:${element.name}`
      })
    }
    return list
  }

  const fetchUser = async ( userParams ) => {
    const { data: users} = await userServices.get({
      size: 99999, page: 1, search: `info:${userParams}`
    })
    const list = [{
      label: 'ALL'
    }]
    for (const i in users) {
      const element = users[i];
      list.push({
        label: `${element.username} - ${element.info.fullname}`,
        name: `${element.username}`
      })
    }
    return list
  }
  useEffect(() => {
    console.log('expire', coupon.expire)
  }, [coupon])
  return <div>
    <Typography  className={classes.root} variant="h2">
      Form Coupon
    </Typography>
      <Grid container>

        <Grid className={classes.content} item xs={12} sm={12} md={6}>
          <Card className={classes.card} >
            <Typography variant="h5" >
              General
            </Typography>
            <TextField
              label="Code"
              className={classes.textField}
              variant="outlined"
              disabled={params.code}
              fullWidth
              value={coupon.code}
              onChange={({ target: { value: code } }) => setCoupon({...coupon, code})}
            />
            <br/>
            <TextField
              label="Quota"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={coupon.quota}
              type="number"
              onChange={({ target: { value: quota } }) => {
                setCoupon({...coupon, quota: parseInt(quota)})
              }}
                />
            <br/>
            <TextField
              label="Value"
              variant="outlined"
              fullWidth
              type="number"
              className={classes.textField}
              value={coupon.value}
              onChange={({ target: { value } }) => setCoupon({...coupon, value: parseInt(value)})}
            />
            <br/>
            <FormControl className={classes.textField} 
            style={{width: '100%'}}
               fullwidth variant="outlined">
              <InputLabel id="demo-controlled-open-select-label">Type</InputLabel>
              <Select
                label="Type"
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={openType}
                onClose={()=> setOpenType(false)}
                onOpen={o => setOpenType(o)}
                value={coupon.type}
                onChange={({target: { value: type}}) => setCoupon({...coupon, type})}
              >
                <MenuItem value={'='}>Exact</MenuItem>
                <MenuItem value={'-'}>Minus</MenuItem>
                <MenuItem value={'*'}>Percentage (0 - 100)</MenuItem>
              </Select>
            </FormControl>
            <br/>

            <FormControlLabel
              className={classes.textField}
              control={
                <Checkbox
                  checked={isExpired}
                  onChange={({target: { checked: isExpired }}) => setIsExpired(isExpired)}
                  color="primary"
                />
              }
              label="Expired"
            />
            {
              isExpired && 
                <TextField
                  variant="outlined"
                  label="Expired"
                  type="datetime-local"
                  className={classes.textField}
                  value={coupon.expire}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={({ target: { value: expire } }) => {
                    setCoupon({...coupon, expire})
                  }}
                />
              
            }
          </Card>
        </Grid>
        <Grid className={classes.content} item xs={12} sm={12} md={6}>
          <Card className={classes.card} >
            <Typography variant="h5">
              Conditions
            </Typography>
            <AutoComplete fetchData={fetchGames} onChange={(evt, value) => setCoupon({
              ...coupon,
              conditions: {
                ...coupon.conditions,
                game: value ? value.name ? value.name : undefined : undefined
              }
            })}
            
            selected={coupon.conditions && coupon.conditions.game}
            fullWidth label="Game"/>
            <AutoComplete fetchData={fetchUser} 
             onChange={(evt, value) => setCoupon({
              ...coupon,
              conditions: {
                ...coupon.conditions,
                player_username: value ? value.name ? value.name : undefined : undefined
              }
            })}
            selected={coupon.conditions && coupon.conditions.player_username}
            fullWidth label="Player"/>
          </Card>
          <Button
            style={{
              margin: 10,
              position: "absolute",
              right: 10
            }}
            color="primary"
            variant="contained"
            onClick={() => saveCoupon()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
  </div>
}

export default CouponForm