import React, { createContext, useEffect, useRef } from 'react'
import { InitialStates } from './constants'
const GlobalContext = createContext(InitialStates)
const DispatchContext = React.createContext(undefined);

const globalStateReducer = (state, action) => {
  // switch(action.type){
  //   case Constants.SET_LOADER: return {...state, loader: action.payload}
  // }
  state[action.type] = action.payload
  return state
}

// export const GlobalStateProvider = ({children}) => {
//   const [state, dispatch] = useReducer(globalStateReducer, InitialStates)
//   return (
//     <GlobalContext.Provider value={[state, dispatch]}>
//       {/* <DispatchContext.Provider value={dispatch}> */}
//         {children}
//       {/* </DispatchContext.Provider> */}
//     </GlobalContext.Provider>
//   )
// }


const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    (state, newValue) => {
      return ({ ...state, ...newValue })
    },
    InitialStates
  );
  return (
    <GlobalContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </GlobalContext.Provider>
  );
};

// /**
//  * @param {string} k key of the global state
//  * @returns {[Object, Function}]}
//  */
// export const UseGlobalState = (k) => {
//   const [state, dispatch] = useContext(GlobalContext)
//   const setState = (key, value) => {
//     dispatch({
//       type: key,
//       payload: value
//     })
//   }
//   return [
//     state[k],
//     (v) => setState(k, v)
//   ]
// }

/**
 * @param {string} k key of the global state
 * @returns {[Object, Function}]}
 */
const UseGlobalState = (k) => {
  const [state, dispatch] = [
    React.useContext(GlobalContext),
    React.useContext(DispatchContext)
  ]
  const setState = (key, value) => {
    let v = {}
    v[key] = value
    dispatch({
      ...state, ...v
    })
  }
  return [
    state[k],
    (v) => setState(k, v)
  ]
};

const Loader = () => {
  let [globalLoader, setGlobalLoader] = UseGlobalState('globalLoader')

  /**
   * 
   * @param {string} key
   * @param {string} message
   */
  const startLoader = (key, message) => {
    let loader = globalLoader || {}
    loader[key] = message
    setGlobalLoader(loader)
  }
  /**
   * 
   * @param {string} key 
   */
  const finishLoader = (key) => {
    let loader = globalLoader || {}
    delete loader[key]
    setTimeout(() => setGlobalLoader(loader), 2000)

  }
  return { startLoader, finishLoader, globalLoader }
}
export {
  GlobalStateProvider,
  UseGlobalState,
  Loader
}

export const usePrev = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}