
import React, { useEffect } from 'react'
import { makeStyles, Card, CardContent, Typography, TextField, Select, MenuItem, FormControl, OutlinedInput, InputLabel, IconButton, InputAdornment } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';
import { useState } from 'react';
import { Loader } from 'services/context'
import User from 'services/user'

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(2)
  },
  select: {
    width: '100%'
  }
}))
const AccountInfo = props => {
  const { startLoader, finishLoader, globalLoader } = Loader('globalLoader')
  const { user, isEdit, validate } = props
  const classes = useStyles()
  const [values, setValues] = useState({
    showPassword: false,
    user
  })
  const [errors, setErrors] = useState({
    username: '',
    email: ''
  })
  const items = [{
    value: 1,
    text: 'Active'
  },
  {
    value: 0,
    text: 'Not Active'
  }
  ]
  const onUserChange = user => props.onUserChange && props.onUserChange(user)

  const handleChange = prop => event => {
    let user = { ...values.user, [prop]: event.target.value }
    setValues({ ...values, user });
    onUserChange(user)
    validateAction[prop] && validateAction[prop](event.target.value)
  }
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  }
  const handleMouseDownPassword = event => {
    event.preventDefault();
  }

  const isUsernameExists = (username) => {
    setErrors({ ...errors, username: '' })

    if (username === '') return setErrors({ ...errors, username: 'Required' })

    if (globalLoader.usernameExists) return
    startLoader('usernameExists', 'Checking username is exists')
    User.usernameExists(username).then(value => {
      setErrors({ ...errors, username: 'Username exists' })
    }).catch(err => {
    }).finally(() => finishLoader('usernameExists'))
  }
  const isEmailExists = (email) => {
    setErrors({ ...errors, username: '' })
    if (email === '') return setErrors({ ...errors, email: 'Required' })

    if (globalLoader.emailExists) return
    startLoader('emailExists', 'Checking email is exists')
    User.emailExists(email).then(value => {
      setErrors({ ...errors, email: 'Email exists' })
    }).catch(err => {
    }).finally(() => finishLoader('emailExists'))
  }
  useEffect(() => {
    let { user } = props
    setValues({ ...values, user: user })
  }, [user])
  const validateAction = {
    username: isUsernameExists,
    email: isEmailExists
  }
  return (
    <Card>
      <CardContent>
        <Typography >Account Info</Typography>
        <br />
        <TextField
          error={errors.username !== ''}
          helperText={errors.username}
          className={classes.margin}
          label="Username"
          variant="outlined"
          disabled={isEdit}
          fullWidth
          value={values.user.username}
          onChange={handleChange('username')}
        />
        <TextField
          className={classes.margin}
          error={errors.email !== ''}
          helperText={errors.email}
          label="Email"
          inputMode="email"
          variant="outlined"
          value={values.user.email}
          onChange={handleChange('email')}
          fullWidth
        />
        <FormControl fullWidth className={clsx(classes.margin, classes.textField)} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.user.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={60}
          />
        </FormControl>
        <FormControl className={clsx(classes.margin, classes.select)} variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
          <Select
            label="Status"
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={values.user.status || 0}
            onChange={handleChange('status')}
          >
            <MenuItem value={1}>Active</MenuItem>
            <MenuItem value={0}>Not Active</MenuItem>
          </Select>
        </FormControl>

      </CardContent>
    </Card>
  )
}

export default AccountInfo