import React, {useState, useEffect} from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button'
const ConfirmDialog = (props) => {
  const {title, open, message} = props
  const [dialogData, setDialogData] = useState({
    open: open || false,
    title: title,
    message: message
  })
  const onOkay = () => {
    props.onOkay && props.onOkay()
  }
  const onCancel = () => {
    props.onCancel && props.onCancel()
  }
  useEffect(() => {
    // console.log(props)
    let {open, title, message} = props
    setDialogData({
      open, title, message
    })
  }, [open, title, message])
  return (
    <Dialog
      open={dialogData.open}
    >
      <DialogTitle id="alert-dialog-title">{dialogData.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogData.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onOkay} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
      
    </Dialog>
  )
}
export default ConfirmDialog