// eslint-disable-next-line no-unused-vars
// import Axios, { AxiosRequestConfig } from 'axios'
// import { FORMAT_HTTP_HEADERS } from 'opentracing'
// import https from 'https'
import Axios, { AxiosRequestConfig } from 'axios'
// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line no-unused-vars
class RestHTTPClientError extends Error {
  /**
   * 
   * @param {Error} e 
   */
  constructor(e) {
    super(e)
    this.code = 500
    this.message = ''
    // this.stack = e
    this.stack = e.stack
  }
}
/**
 * @typedef {Object} Result
 */
/**
 * @typedef {Result} Response
 */

export default class Request {
  /**
   * @param {AxiosRequestConfig} cfg axios request config instance
   */
  constructor(cfg) {
    this.client = Axios.create({
      ...cfg,
      validateStatus: s => s >= 200 && s < 300
    })
    this.Error = RestHTTPClientError

  }
  /**
   *
   * @param {Error} e
   */
  setError(e) {
    this.Error = e
  }
  /**
   * @param {AxiosRequestConfig} cfg
   * @returns {Promise<Response>}
   */
  async request(cfg) {
    /**
     * @type {[Error, import('axios').AxiosResponse]}
     */
    let [err, res] = [null, null]
    try {
      res = await this.client.request(cfg)
    } catch (e) {
      err = new this.Error(e)
      if (e.response) {
        err.code = e.response.status
        err.message = e.response.data
      } else if (e.request) {
        err.code = 500
        err.message = 'The request was made but no response was received'
      } else err.code = 500
      if (err.code === 401) {
        return window.location = '/backend/openid/auth_url'
      }
      if (err.code === 403) {
        return window.location = '/unauthorized'
      }
    }
    if (err) throw err
    return res ? res.data : null
  }
  /**
   *
   * @param {string} path
   * @param {(JSON|string)} data request body json or string urlencoded
   * @param {AxiosRequestConfig} [cnf]
   */
  put(path, data, cnf) {
    /**
     * @alias AxiosRequestConfig
     */
    let config = {
      method: 'put',
      url: path,
      data
    }
    config = cnf ? { ...config, ...cnf } : config

    config.headers = config.headers && {}
    if (typeof data === 'string') {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    } else if (typeof data === 'object') {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json'
      }
    }

    return this.request(config)
  }
  patch(path, data, cnf) {
    /**
     * @alias AxiosRequestConfig
     */
    let config = {
      method: 'patch',
      url: path,
      data
    }
    config = cnf ? { ...config, ...cnf } : config

    config.headers = config.headers && {}
    if (typeof data === 'string') {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    } else if (typeof data === 'object') {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json'
      }
    }

    return this.request(config)
  }
  /**
   *
   * @param {string} path
   * @param {(JSON|string)} data request body
   * @param {AxiosRequestConfig} [cnf]
   */
  post(path, data, cnf) {
    /**
     * @alias AxiosRequestConfig
     */
    let config = {
      method: 'post',
      url: path,
      data
    }
    config = cnf ? { ...config, ...cnf } : config
    config.headers = config.headers && {}
    if (typeof data === 'string') {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    } else if (typeof data === 'object') {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json'
      }
    }
    return this.request(config)
  }
  /**
   * @param {string} path
   * @param {AxiosRequestConfig} [cnf]
   */
  get(path, cnf) {
    // console.log(path)
    let config = {
      method: 'get',
      url: path
    }
    config = cnf ? { ...config, ...cnf } : config
    return this.request(config)
  }
  /**
   *
   * @param {string} path
   * @param {AxiosRequestConfig} [cnf]
   */
  del(path, cnf) {
    let config = {
      method: 'delete',
      url: path
    }
    config = cnf ? { ...config, ...cnf } : config
    return this.request(config)
  }
}
