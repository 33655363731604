const Constant = {
  GLOBAL_LOADER: 'GLOBAL_LOADER',
  
}
const InitialStates = {
  loggedIn: false,
  globalLoader: {},
  /**
   * @type {User}
   */
  userData: {
     
  }
}
export {
  Constant,
  InitialStates
}