import React from 'react'
import Modal from '@material-ui/core/Modal'
import ACL from './acl'
import RBAC from './rbac/index'
import { useState } from 'react'
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
  },
  ButtonDone: {
    display: 'flex',
    width: '100%'
  }
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}


/**
 * 
 * @param {object} props
 * @param {boolean} props.open
 * @param {string} props.username
 */
const AccessControl = (props) => {
  
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Modal open = {props.open}
    className={classes.modal}

      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>

        <div className={classes.paper}>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Roles" />
            <Tab label="Access Control" />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <RBAC username = {props.username}/>
        </TabPanel>
        <TabPanel value={value} username = {props.username} index={1}>
          <ACL/>
        </TabPanel>
        <Button className={classes.ButtonDone} onClick={() => props.onDone && props.onDone()}>
          Done
        </Button>
        </div>
      </Fade>
    </Modal>
  )
}

export default AccessControl