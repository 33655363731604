import Request from '../request'
const client = new Request()
export const protocols = ['http', 'https']
/**
 * 
 * @param {API} api 
 * @returns {API}
 */
export const ConvertKongApiSchema = (api) => {
  let { paths } = api
  paths = paths[0].split('/')
  const path_handling = paths[paths.length - 1]
  return {
    ...api,
    path_handling
  }
}
/**
 * 
 * @param {API} api 
 * @returns {API}
 */
export const ConvertKongApiSchemaReverse = (api) => {
  let { paths } = api
  paths = paths[0].split('/')
  const path_handling = paths[paths.length - 1]
  return {
    ...api,
    path_handling
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  api: {
    get: async (name) => {
      let p = name ? `/${name}` : ''
      let apis = await client.get(`/backend/services/apis${p}`)
      return apis
    },
    /**
     * @param {API} api
     */
    put: async (api) => {
      let p = `${api.name}`
      let apis = await client.put(`/backend/services/apis/${p}`, api)
      return ConvertKongApiSchema(apis)
    },
    getSwagger: async (name) => {
      let swagger = await client.get(`/backend/services/apis${name}/swagger`)
      return swagger
    },
    putSwagger: async (name, swagger) => {
      let s = await client.put(`/backend/services/apis${name}/swagger`, swagger)
      return s
    }
  },
  backends: {
    get: async name => {
      let p = name ? `/${name}` : ''
      let backend = await client.get(`/backend/services/backends${p}`)
      return backend
    },
    put: async (name, b) => {
      let backend = await client.put(`/backend/services/backends/${name}`, b)
      return backend
    },
    delete: async (name) => {
      let backend = await client.del(`/backend/services/backends/${name}`)
      return backend
    },
  },
  consumer: {
    get: async name => {
      let p = name ? `/${name}` : ''
      let backend = await client.get(`/backend/services/backends${p}`)
      return backend
    },
  }
}