import React from 'react'
import GameInfo from './components/GameInfo'
import Releases from './components/Releases'
import Scores from './components/Scores'
export default props => {
  return (
    <>
      <GameInfo/>
      <Scores/>
      <Releases/>
    </>
  )
}