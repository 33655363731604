
import React, { useEffect } from 'react'
import { makeStyles, Card, CardContent, Typography, TextField, FormControl, Radio, FormLabel, RadioGroup, FormControlLabel, Box } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useState } from 'react';
import DateFnsUtils from '@date-io/moment';
import clsx from 'clsx'
import theme from 'theme';
const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  marginControl: {
    padding: theme.spacing(2),
  }
}))
const UserProfile = props => {
  const { profile, validate } = props
  const classes = useStyles()
  const [values, setValues] = useState({
    profile
  })
  const onProfileChange = profile => props.onProfileChange && props.onProfileChange(profile)

  const handleChange = prop => event => {
    let profile = { ...values.profile, [prop]: event.target ? event.target.value : event }
    setValues({ ...values, profile });
    onProfileChange(profile)
  }

  useEffect(() => {
    let { profile } = props
    setValues({ ...values, profile })
  }, [profile])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>

      <Card>
        <CardContent>
          <Typography >Profile</Typography>
          <br />
          <TextField
            className={classes.margin}
            label="Fullname"
            variant="outlined"
            fullWidth
            value={values.profile.fullname}
            onChange={handleChange('fullname')}

          />
          <TextField
            className={classes.margin}
            label="Phone Number"
            inputMode='numeric'
            variant="outlined"
            value={values.profile.phone_number}
            onChange={handleChange('phone_number')}
            fullWidth
          />
          <Box border={0.5} borderColor='#cacaca' borderRadius={5} className={clsx(classes.margin, classes.marginControl)}>

            <FormControl fullWidth component="fieldset">
              <FormLabel component="legend">Gender</FormLabel>
              <RadioGroup aria-label="gender" name="gender2" value={values.profile.gender} onChange={handleChange('gender')}>
                <FormControlLabel
                  value="female"
                  control={<Radio color="primary" />}
                  label="Female"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="male"
                  control={<Radio color="primary" />}
                  label="Male"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box border={0.5} borderColor='#cacaca' borderRadius={5} className={clsx(classes.margin, classes.marginControl)}>

            <DatePicker
              variant='dialog'
              className={clsx(classes.margin, classes.marginControl)}
              value={values.profile.birthdate}
              format="YYYY-MM-DD"
              onChange={handleChange('birthdate')}
              label="Datebirth"
            />
          </Box>
        </CardContent>
      </Card>
    </MuiPickersUtilsProvider>
  )
}

export default UserProfile