import { Loader } from '../../services/context'
import useConfigService from '../../services/publisher/configuration'
import { Button, Card, CardContent, FormControl, Grid, InputLabel, makeStyles, Select, TextField, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { MenuItem } from 'material-ui'
import React, { useEffect } from 'react'

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(2)
  },
  select: {
    width: '100%'
  }
}))
const PublisherConfiguration = ({ publisher, onSuccess }) => {
  const { startLoader, finishLoader, globalLoader} = Loader()
  const { 
    configuration, setConfiguration, loading, update
  } = useConfigService(publisher)
  
  const _onSuccess = () => {
    onSuccess && onSuccess()
  }
  
  useEffect(() => {
    if(loading) startLoader('fee-loading', 'Fetching fees')
    if(!loading) finishLoader('fee-loading')
  }, [loading])
  const classes = useStyles()
  
  return (
    <Card>
      <CardContent>
        <br />
        <Grid container spacing={5}>
          <Grid item md={6} sm={12}>
            
            <TextField
              className={classes.margin}
              label="Register Price"
              variant="outlined"
              fullWidth
              type={`number`}
              value={configuration && configuration.register_price}
              onChange={({ target: { value } }) => {
                setConfiguration({
                  ...configuration,
                  register_price: parseInt(value || '0') || 0
                })
              }}
            />
            <TextField
              className={classes.margin}
              label="Upoin Payment Item Name"
              variant="outlined"
              fullWidth
              value={configuration && configuration.register_upoint_name}
              onChange={({ target: { value: register_upoint_name } }) => {
                setConfiguration({
                  ...configuration,
                  register_upoint_name
                })
              }}
            />
            <TextField
              className={classes.margin}
              label="Minimum withdrawal amount"
              variant="outlined"
              fullWidth
              value={configuration && configuration.minimum_withdrawal_balance}
              onChange={({ target: { value: minimum_withdrawal_balance } }) => {
                
                setConfiguration({
                  ...configuration,
                  minimum_withdrawal_balance: parseInt(minimum_withdrawal_balance || '0') || 0
                })
              }}
            />
          </Grid>

          <Grid item md={6} sm={12}>
            <TextField
              className={classes.margin}
              label="Minimum Playstore Price"
              variant="outlined"
              fullWidth
              value={configuration && configuration.minimum_playstore_price}
              onChange={({ target: { value: minimum_playstore_price } }) => {
                
                setConfiguration({
                  ...configuration,
                  minimum_playstore_price: parseInt(minimum_playstore_price || '0') || 0
                })
              }}
            />
            {/* <TextField
              className={classes.margin}
              label="Activation Platform Fee"
              variant="outlined"
              fullWidth
              value={configuration && configuration.publisher_activation_platform_fee}
              onChange={({ target: { value: publisher_activation_platform_fee } }) => {
                
                setConfiguration({
                  ...configuration,
                  publisher_activation_platform_fee: parseInt(publisher_activation_platform_fee || '0') || 0
                })
              }}
            /> */}
          </Grid>
        </Grid>
        <Button onClick={() => { update(_onSuccess) }}>Save</Button>
      </CardContent>
    </Card>)
}

export default PublisherConfiguration