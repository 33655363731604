import React, { Component } from 'react'
import { UseGlobalState } from 'services/context'
import { CircularProgress, Snackbar, Card, CardContent, Typography, makeStyles } from '@material-ui/core'

// class GlobalLoader extends Component {
//   render(){
//     const [globalLoader, setGlobalLoader] = UseGlobalState('globalLoader')

//     return (
//       <CircularProgress />
//     )
//   }
// }

const useStyles = makeStyles(theme => ({
  marginAutoContainer: {
    display: 'flex',
  },
  marginAutoItem: {
    margin: 'auto'
  },
  alignItemsAndJustifyContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10
  },
}))

const GlobalLoader = () => {
  const classes = useStyles()
  const [loader] = UseGlobalState('globalLoader')
  return (<div>
    <Snackbar anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }} open={loader ? true : false}>
      <div>
        {
          loader && Object.keys(loader).map(index => {
            return (
              <Card key={index}
                className={classes.alignItemsAndJustifyContent}>
                <CircularProgress size={40} /><Typography> &nbsp;&nbsp; {loader[index]}</Typography>
              </Card>
            )
          })
        }
      </div>
    </Snackbar>
  </div>
  )
}
export default GlobalLoader