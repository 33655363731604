import React from 'react'
import { Table, TableHead, TableCell, TableRow, TableBody, Paper, Button, Link, Container } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import services from 'services/api'
import { useEffect } from 'react'
import { Loader, UseGlobalState } from 'services/context'
import { useState } from 'react'
import { Add } from '@material-ui/icons'
import { GLOBAL_KEY, INDEX_BACKEND } from '../header'
import Wrapper from '../wraper'
import { Apis } from '../Apis'
export const Backends = () => {
  const { startLoader, finishLoader, globalLoader } = Loader()
  const [state, setState] = useState({
    backends: {
      next: {},
      /**
        @type {Array<Backend>}
      */
      data: []
    }
  })
  const LOADER_KEY = 'BackendData'
  const fetchBackend = () => {
    if (globalLoader[LOADER_KEY]) return
    startLoader(LOADER_KEY, 'Fetching Backends')

    services.backend.get().then(backends => {
      setState({ backends })
    }).catch(e => {

    }).finally(() => {
      finishLoader(LOADER_KEY)
    })
  }
  const componentDidMount = () => {
    fetchBackend()
  }
  useEffect(() => {
    componentDidMount()
  }, [])
  return (
    <Paper>
      <Button>
        <Add />
          Add Backend
        </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Backend Name</TableCell>
            <TableCell>Backend</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            state.backends.data.map((v, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Link component={RouterLink} to={`/services/backends/${v.id}`}>{v.name || v.id} </Link>
                </TableCell>
                <TableCell>{v.service.id}</TableCell>
                <TableCell>
                  <Button>Swagger</Button>
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </Paper>
  )
}

export default () => {
  const [_, setValue] = UseGlobalState(GLOBAL_KEY)
  setValue(INDEX_BACKEND)
  return (
    <Wrapper>
      <Apis />
    </Wrapper>
  )
}