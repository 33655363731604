import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { hot } from 'react-hot-loader';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import { hexToRgb } from '@material-ui/core';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const Application = () => (
  <ThemeProvider theme={theme}>
      <MuiThemeProvider>

      <Router history={browserHistory}>
        <Routes />
      </Router>
      </MuiThemeProvider>

  </ThemeProvider>
);
// class App extends Component {
//   render() {
//     return (
//       <ThemeProvider theme={theme}>
//         <Router history={browserHistory}>
//           <Routes />
//         </Router>
//       </ThemeProvider>
//     );
//   }
// }
export default hot(module)(Application)