import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { DefaultTheme } from '@material-ui/styles';
import { Avatar, Typography, withStyles, CircularProgress } from '@material-ui/core';
import User from 'services/user/index'
import { UseGlobalState } from 'services/context';

/**
 * @param {DefaultTheme} theme
 */
const useStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
});

const ProfileComp = (props) => {
  const [loading, setLoading] = useState(false)
  const [globalUser, setGlobalUser] = UseGlobalState('USER')
  const user = globalUser ? {
    name: globalUser.name,
    avatar: globalUser.avatar,
    bio: globalUser.role
  } : {}
  const className = PropTypes.string
  const componentDidMount = () => {
    setLoading(true)
    getUser().then(() => { }).catch(() => {

    }).finally(() => {
      setTimeout(() =>
        setLoading(false),
        2000
      )
    })
  }
  const getUser = async () => {
    let session = await User.getMe()
    setGlobalUser({
      name: session.info.fullname,
      avatar: session.info.avatar || '',
      username: session.username
    })
    return session

  }
  useEffect(componentDidMount, [])
  const { classes, ...rest } = props
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {
        loading && (
          <CircularProgress color="inherit" />
        )
      }
      {
        (!loading) && (
          <div>
            <Avatar
              alt="Person"
              className={classes.avatar}
              component={RouterLink}
              src={user.avatar}
              to="/settings"
            />
            <Typography
              className={classes.name}
              variant="h4"
            >
              {user.name}
            </Typography>
            <Typography variant="body2">{user.bio}</Typography>
          </div>
        )
      }
    </div>
  )
}
export default withStyles(useStyles)(ProfileComp)
