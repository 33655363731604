import Request from '../request'
import qs from 'query-string'
const req = new Request({
  baseURL: '/backend/events'
})
const evt = {
  updateEvent(publisher, name, eventName, status, comment) {
    return req.put(`/${eventName}/publishers/${publisher}/games/${name}`, {
      status,
      comment: `${comment} (${status})`
    })
  },
  events(page, size) {
    let p = {
      page, size
    }
    p = qs.stringify(p)
    return req.get(`/?${p}`)
  },
  eventScore(publisher, name, event, page, size) {
    let p = {
      page, size
    }
    p = qs.stringify(p)
    return req.get(`/${event}/publishers/${publisher}/games/${name}/scores?${p}`)
  },
  comments(publisher, name, event, page, size) {
    let p = {
      page, size
    }
    p = qs.stringify(p)
    return req.get(`/${event}/publishers/${publisher}/games/${name}/comments?${p}`)
  },
  eventDetail(publisher, name, event) {
    return req.get(`/${event}/publishers/${publisher}/games/${name}`)
  }
}
export default evt