import { Loader } from '../../services/context'
import useRewardModule from '../../services/rewards'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, makeStyles, Select, TextField, Typography } from '@material-ui/core'
import AutoComplete from '../../components/AutoComplete'
import { MenuItem } from 'material-ui'
import { useHistory, useParams } from 'react-router'
import gameServices from '../../services/games'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  card:{
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  buttonGroup: {
    padding: 20,
    position: "absolute",
    right: 0
  },
  textField: {
    marginTop: 10
  }
}))

const RewardForm = () => {
  const { startLoader, finishLoader} = Loader()
  const [openType, setOpenType] = useState(false)
  const params = useParams()
  const history = useHistory()
  const { 
    reward,
    setReward,
    get,
    insert,
    update,
    loading: { updateLoading, insertLoading }
   } = useRewardModule()
   const [coupon, setCoupon] = useState(
     reward.extras
   )
  
   const classes = useStyles()

  const fetchGames = async (gameParams) => {
    const {games} = await gameServices.getAll(1, 10000)
    const list = [{
      label: 'ALL'
    }]
    for (const i in games) {
      const element = games[i];
      list.push({
        label: `${element.publisher.username} - ${element.name}`,
        name: `${element.publisher.username}:${element.name}`
      })
    }
    return list
  }
  const _inputChange = ({ target: { files } }) => {
    if(files.length > 0) {
      const file = files[0]
      const reader = new FileReader()
      reader.onloadend = () => {
        // log to console
        // logs data:<type>;base64,wL2dvYWwgbW9yZ...
        // console.log(reader.result)
        setReward({...reward, image: reader.result})
      };
      reader.readAsDataURL(file);
    }
  }
  const inputFile = useRef(null) 
  const goToListPage = () => {
    setTimeout(() => history.push(`/rewards`), 1000)
  }
  const save = () => {
    if(reward.extras.type === '*' && reward.extras.value > 100) {
      alert(`Coupon value with type Percentage should be not more than 100`)
      return
    }
    if(!reward.extras.conditions.game) return alert('Coupon game required')
    !params.id ?
      insert(goToListPage)
    : update(params.id, goToListPage)
  } 
  useEffect(() => {
    setReward({...reward, extras: coupon})
  }, [coupon]) 
  useEffect(() => {
    if(reward.extras !== coupon)
    setCoupon(reward.extras)
  }, [reward.extras])
  useEffect(() => {
    if(params.id) get(params.id)
  }, [])
  useEffect(() => {
    updateLoading ? startLoader('update-reward', 'Updating Reward') : finishLoader('update-reward')
    insertLoading ? startLoader('insert-reward', 'Inserting Reward') : finishLoader('insert-reward')
  }, [updateLoading, insertLoading])
  return  <div className={classes.root} >
    <Typography  variant="h2">
      Form Reward
    </Typography>
    <Card className={classes.card}>
      <Typography variant="h3" >
        Details
      </Typography>
        <br/>
        <TextField
          label="name"
          variant="outlined"
          fullWidth
          className={classes.textField}
          InputLabelProps={{ shrink: true }}
          value={reward.name}
          onChange={({ target: { value: name } }) => {
            setReward({...reward, name})
          }}
            />
        <br/>
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          multiline={true}
          InputLabelProps={{ shrink: true }}
          className={classes.textField}
          value={reward.description}
          onChange={({ target: { value: description } }) => {
            setReward({...reward, description})
          }}
            />
          <TextField
            label="Price (in Coin)"
            variant="outlined"
            fullWidth
            type="number"
            className={classes.textField}
            value={reward.price}
            onChange={({ target: { value: price } }) => {
              setReward({...reward, price})
            }}
              />
            <br/>
       <TextField
        variant="outlined"
        label="Expired"
        type="datetime-local"
        className={classes.textField}
        value={reward.expire}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        onChange={({ target: { value: expire } }) => {
          setReward({...reward, expire})
        }}
      />
      <img src={reward.image}/>
      <br/>
      <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={_inputChange}/>
      <Button variant="outlined" onClick={() => inputFile.current.click()}>Change Picture</Button>
    </Card>
    {
      reward.type === 'coupon' && (
        <Card className={classes.card}>
      
        <Typography variant="h3" >
            Coupon
          </Typography>
          <Grid container>
            <Grid className={classes.content} item xs={12} sm={12} md={6}>
              <Card className={classes.card} >
                <Typography variant="h5" >
                  General
                </Typography>
                <br/>
                <TextField
                  label="Value"
                  variant="outlined"
                  fullWidth
                  type="text"
                  className={classes.textField}
                  value={coupon.value.toString()}
                  onChange={({ target: { value } }) => setCoupon({...coupon, value: parseInt(value)})}
                />
                <br/>
                <FormControl className={classes.textField} 
                style={{width: '100%'}}
                  fullwidth variant="outlined">
                  <InputLabel id="demo-controlled-open-select-label">Type</InputLabel>
                  <Select
                    label="Type"
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    open={openType}
                    onClose={()=> setOpenType(false)}
                    onOpen={o => setOpenType(o)}
                    value={coupon.type}
                    onChange={({target: { value: type}}) => setCoupon({...coupon, type})}
                  >
                    <MenuItem value={'='}>Exact</MenuItem>
                    <MenuItem value={'-'}>Minus</MenuItem>
                    <MenuItem value={'*'}>Percentage (0 - 100)</MenuItem>
                  </Select>
                </FormControl>
                <br/>
              </Card>
            </Grid>
            <Grid className={classes.content} item xs={12} sm={12} md={6}>
              <Card className={classes.card} >
                <Typography variant="h5">
                  Conditions
                </Typography>
                <AutoComplete fetchData={fetchGames} onChange={(evt, value) => setCoupon({
                  ...coupon,
                  conditions: {
                    ...coupon.conditions,
                    game: value ? value.name ? value.name : undefined : undefined
                  }
                })}
                
                selected={coupon.conditions && coupon.conditions.game}
                fullWidth label="Game"/>
              </Card>
            </Grid>
          </Grid>
        </Card>
      )
    }
    <Button
      style={{
        margin: 10,
        position: "absolute",
        right: 10
      }}
      color="primary"
      variant="contained"
      onClick={() => save()}
    >
      Save
    </Button>
   
</div>
}

export default RewardForm