import { Card, makeStyles, Table, TableCell, TableHead, TableBody, TableRow, ButtonGroup, Button, Link, TablePagination  } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import couponServices from '../../services/coupon'
import { Loader } from '../..//services/context'
import IconNext from '@material-ui/icons/ArrowRight'
import IconPrev from '@material-ui/icons/ArrowLeft'
import moment from 'moment-timezone'

import { Link as RouterLink } from 'react-router-dom'
const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  buttonGroup: {
    padding: 20,
    position: "absolute",
    right: 0
  }
}))
const FETCH_COUPON = 'fetch-coupon'
export const CouponList = () => {
  const classes = useStyles()
  const {startLoader, finishLoader, globalLoader} = Loader()
  const [limit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [totalRow, setTotalRow] = useState(0)
  const [coupons, setCoupons] = useState([])
  const componentDidMount = () => {
    setOffset(0)
    fetchCoupon()
  }

  const fetchCoupon = () => {
    if(globalLoader[FETCH_COUPON]) return
    startLoader(FETCH_COUPON, 'Fetching coupon data')
    couponServices
    .fetch(limit, offset)
    .then(coupons => {
      setCoupons(coupons.data)
      setTotalRow(coupons.total_row.count)
    }).catch(e => {
      e.code === 404 && offset > 0 && setOffset(offset - limit)
    }).finally(() => finishLoader(FETCH_COUPON))
  }

  useEffect(() => {
    if(globalLoader[FETCH_COUPON]) return
    console.log({offset})
    fetchCoupon()
  }, [offset])
  useEffect(componentDidMount, [])
  return <>
    <div className={classes.root} style={{height: 40}}>
      <Link style={{
        position: 'absolute',
        right: 15
      }} to={`/coupon/form`} component={RouterLink}>
        <Button
          color="primary"
          variant="contained"
        >
          Add
        </Button>
      </Link>

    </div>
    <Card className={classes.root}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Coupon Code
            </TableCell>
            <TableCell>
              Creator
            </TableCell>
            <TableCell>
              Conditions
            </TableCell>
            <TableCell>
              Quota
            </TableCell>
            <TableCell>
              Expire
            </TableCell>
            <TableCell>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            coupons.map((c, i) => (
              <TableRow key={`c-${i}`}>
                <TableCell>
                  {c.code}
                </TableCell>
                <TableCell>
                  {c.creator}
                </TableCell>
                <TableCell>
                  {
                    c.conditions && 
                    Object.keys(c.conditions).map((n, i) => (
                      <div key={n}>{`${n}: ${c.conditions[n]}`}</div>
                      ))
                  }
                </TableCell>
                <TableCell>
                  {c.quota}
                </TableCell>
                <TableCell>
                  {c.expire && moment(c.expire).format('YYYY-MM-DD HH:mm')}
                </TableCell>
                <TableCell>
                  <Link to={`/coupon/form/creator/${c.creator}/code/${c.code}`} component={RouterLink}>
                    <Button
                      color="primary"
                      variant="contained"
                    >
                      Detail
                    </Button>
                  </Link>
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      <TablePagination
            component="div"
            count={totalRow}
            onChangePage={(evt, page) => {
              setOffset((page)* 10)
            }
            }
            onChangeRowsPerPage={(evt, page) => {}}
            page={Math.ceil((offset / 10))}
            rowsPerPage={10}
            rowsPerPageOptions={[10]}
          />
      {/* <ButtonGroup className={classes.buttonGroup} size="small" aria-label="small button group">
        {
          offset > 0 &&
          <Button onClick={() => setOffset(offset - limit)}>
            <IconPrev/>
          </Button>
        }
        <Button onClick={() => setOffset(offset + limit)}>
          <IconNext/>
        </Button>
      </ButtonGroup> */}

    </Card>
  </>
}

export default CouponList