import { Loader } from '../../services/context'
import useFeeService from '../../services/publisher/fee'
import { Button, Card, CardContent, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, makeStyles, Select, TextField, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { MenuItem } from 'material-ui'
import React, { useEffect } from 'react'

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(2)
  },
  select: {
    width: '100%'
  }
}))
const PublisherFee = ({ publisher, onSuccess }) => {
  const { startLoader, finishLoader, globalLoader} = Loader()
  const { 
    fee, setFee, loading, update
  } = useFeeService(publisher)
  
  const _onSuccess = () => {
    onSuccess && onSuccess()
  }
  
  useEffect(() => {
    if(loading) startLoader('fee-loading', 'Fetching fees')
    if(!loading) finishLoader('fee-loading')
  }, [loading])
  const classes = useStyles()
  
  return (
    <Card>
      <CardContent>
        <Typography >{publisher} Fees</Typography>
        <br />
        <Grid container spacing={5}>
          <Grid item md={6} sm={12}>
            <Typography >Game</Typography>
            <TextField
              className={classes.margin}
              label="Value"
              variant="outlined"
              fullWidth
              value={fee && fee.game_fee && fee.game_fee.value}
              onChange={({ target: { value } }) => {
                value = parseInt(value || '0') || 0
                if(fee.game_fee.operator === '*') {
                  if(value > 100) {
                    value = 100
                  }
                }
                setFee({
                  ...fee,
                  game_fee: {
                    ...fee.game_fee,
                    value: value
                  }
                })
              }}
            />
            <FormControl className={clsx(classes.margin, classes.select)} variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
              <Select
                label="Status"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={fee && fee.game_fee && fee.game_fee.operator}
                onChange={({ target: { value } }) => {
                  setFee({
                    ...fee,
                    game_fee: {
                      ...fee.game_fee,
                      operator: value
                    }
                  })
                }}
              >
                <MenuItem value={'='}>Equal Amount</MenuItem>
                <MenuItem value={'*'}>Percentage ( 0 - 100 )</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={fee && fee.game_fee && fee.game_fee.affected_by_coupon}
                  onChange={() => {
                    setFee({
                      ...fee,
                      game_fee: {
                        ...fee.game_fee,
                        affected_by_coupon: !fee.game_fee.affected_by_coupon 
                      }
                    })
                    
                  }}
                  name="checked_game_coupon_affected"
                  color="primary"
                />
              }
              label="Affected By Coupon"
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <Typography >Event</Typography>
            <TextField
              className={classes.margin}
              label="Value"
              variant="outlined"
              fullWidth
              value={fee && fee.event_fee && fee.event_fee.value}
              onChange={({ target: { value } }) => {
                value = parseInt(value || '0') || 0
                if(fee.event_fee.operator === '*') {
                  if(value > 100) {
                    value = 100
                  }
                }
                setFee({
                  ...fee,
                  event_fee: {
                    ...fee.event_fee,
                    value: parseInt(value || '0') || 0
                  }
                })
              }}
            />
            <FormControl className={clsx(classes.margin, classes.select)} variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
              <Select
                label="Status"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={fee && fee.event_fee && fee.event_fee.operator}
                onChange={({ target: { value } }) => {
                  setFee({
                    ...fee,
                    event_fee: {
                      ...fee.event_fee,
                      operator: value
                    }
                  })
                }}
              >
                <MenuItem value={'='}>Equal Amount</MenuItem>
                <MenuItem value={'*'}>Percentage ( 0 - 100 )</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={fee && fee.event_fee && fee.event_fee.affected_by_coupon}
                  onChange={() => {
                    setFee({
                      ...fee,
                      event_fee: {
                        ...fee.event_fee,
                        affected_by_coupon: !fee.event_fee.affected_by_coupon 
                      }
                    })
                    
                  }}
                  name="checked_event_coupon_affected"
                  color="primary"
                />
              }
              label="Affected By Coupon"
            />
            
          </Grid>
        </Grid>
        <Button onClick={() => { update(_onSuccess) }}>Save</Button>
      </CardContent>
    </Card>)
}

export default PublisherFee