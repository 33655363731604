import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'


export default function AutoComplete({
  fetchData,
  onChange,
  label,
  selected,
}) {
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [value, setValue] = React.useState({
    name: undefined,
    label: 'ALL'
  })
  const fData = (t) => {
    if(loading) return
    setLoading(true)
    setOptions([])
    return fetchData(t)
    .then((options) => {
      setOptions(options)
      return options
    })
    .catch(e => {})
    .finally(() => setLoading(false))
   }
  const onTextChanged = ({target: { value }}) => {
    fData(value)
  }
  const _onChange = (evt, value) => {
    console.log(value)
    setValue(value)
    onChange(evt, value)
  }
  useEffect(() => {
    if(!value) return
    if(value.name !== selected){
      fData(selected).then(data => {
        console.log(data)
      })
      setValue({
        name: selected,
        label: selected
      })
    }
  }, [selected])
  return (
    <Autocomplete
      style={{marginTop: 10}}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.label}
      options={options}
      loading={loading}
      value={value}
      fullWidth
      onChange={_onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          fullWidth
          variant="outlined"
          onChange={onTextChanged}

          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}
