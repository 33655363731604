/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import GameService, { Release, Comment } from 'services/games'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { useEffect } from 'react'
import { CardContent, Card, Typography, Divider, Grid, makeStyles, Button, List, ListItem, Link } from '@material-ui/core'
import { Loader, UseGlobalState } from 'services/context'
import { Link as RouterLink } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';

import ReleaseDetail from '../releaseDetail/index'
export const RELEASE_LOADER_KEY = 'release'
/**
 * @type {Release[]}
 */
const RELEASES = []
/**
 * @type {Comment[]}
 */
const COMMENTS = []

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2)
  },
  releaseItemRoot: {
    padding: theme.spacing(0)
  },
  content: {
    padding: 0
  }
}))
export default props => {
  const {
    startLoader,
    finishLoader
  } = Loader('globalLoader')
  const params = useParams()
  const [releases, setReleases] = useState(RELEASES)
  const release = releases[0] || undefined
  const [comments, setComments] = useState(COMMENTS)

  const [releaseOpen, setReleaseOpen] = useState(false)
  const [selectedRelease, setSelectedRelease] = useState({})
  const componentDidMount = () => {
    getReleases()
  }

  const getReleases = () => {
    startLoader(RELEASE_LOADER_KEY, 'Fetching game releases')
    GameService
      .releases(params.publisher, params.game)
      .then(releases => setReleases(releases))
      .finally(() => finishLoader(RELEASE_LOADER_KEY))
  }

  const fetchComments = () => {

    startLoader('get-comments', "Getting release comments")
    GameService
      .comments(release.game.publisher.username, release.game.name, release.version)
      .then(comments => {
        setComments(comments)
        finishLoader('get-comments')
      }).catch(e => {
        finishLoader('get-comments')
      })
  }
  useEffect(() => {
    componentDidMount()
  }, [])
  useEffect(() => {
    componentDidMount()
  }, [params.publisher, params.game])
  useEffect(() => {
    release && fetchComments()

  }, [release])

  useEffect(() => {
    !releaseOpen && getReleases()
  }, [releaseOpen])
  const classes = useStyles()

  return (<>
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h2">Game Releases</Typography>
        <List>
          {
            releases.length == 0 && <><br /><Typography variant="h3">No release</Typography></>

          }
          {
            releases.length > 0 && <>
              <><br /><Typography variant="h3">Newest release</Typography></>
              <ReleaseItem release={releases[0]} comment={comments && comments[comments.length - 1]} openRelease={() => {
                setReleaseOpen(true)
                setSelectedRelease(releases[0])
              }} />
            </>
          }
          {
            releases.length > 1 && <><br /><Typography variant="h3">Older releases</Typography></>
          }
          {
            releases.map((r, i) => {
              return i !== 0 && (
                <ReleaseItem key={i} release={r} openRelease={() => {
                  setReleaseOpen(true)
                  setSelectedRelease(r)
                }} />
              )
            }
            )
          }
        </List>

      </CardContent>

      <ReleaseDetail release={selectedRelease} open={releaseOpen} onDone={() => setReleaseOpen(false)} />
    </Card>
  </>)
}

export const ReleaseItem = props => {
  const classes = useStyles()
  /**
   * @type {Release}
   */
  const release = props.release
  const comment = props.comment
  const openRelease = () => {
    props.openRelease && props.openRelease()
  }
  const [globalUser] = UseGlobalState('USER')
  return (
    <>
      <ListItem>
        <Grid item xs={6}>
          <Typography>
            Revision {release.version}<br />
            Description: {release.description} <br />
            Status: {release.status}<br />
            {release.status === 'DRAFTED' && <Link
              href={`/backend/games/publishers/${release.game.publisher.username}/games/${release.game.name}/releases/${release.version}/url`} target="__blank">
              <Button >Play</Button>
            </Link>

            }
            <Button onClick={() => openRelease()}>Update</Button>

          </Typography>
        </Grid>
        <Grid item xs={6}>
          {
            comment && (
              <>
                <Typography variant="h5" >
                  Comments
                </Typography>
                <Typography>
                  {comment.commentator_username === globalUser.username ? 'You' : release.game.publisher.username + `(publisher)`} : {ReactHtmlParser(comment.comment)}
                </Typography>
              </>
            )
          }
        </Grid>
      </ListItem>
      <Divider />
    </>
  )
}