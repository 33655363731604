import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import WithdrawalForm from 'views/WithdrawBalance/Form';
import WithdrawalList from 'views/WithdrawBalance/List';

import { RouteWithLayout } from './components';

import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Dashboard as DashboardView,
  ProductList as ProductListView,
  UserList as UserListView,
  Typography as TypographyView,
  Icons as IconsView,
  Account as AccountView,
  Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Unauthorized as UnauthorizedView,
  UserForm as UserFormView,
  OpenIDClient,
  Services,
  Games,
  Event,
  Coupon,
  Publisher,
  RewardList,
  RewardForm
} from './views';
import { EventDetail } from './views/Event';

const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={UserFormView}
        exact
        layout={MainLayout}
        path="/users/add"
      />
      <RouteWithLayout
        component={UserFormView}
        exact
        layout={MainLayout}
        path="/users/edit/:id"
      />
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={Publisher}
        exact
        layout={MainLayout}
        path="/publisher-portal"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={UnauthorizedView}
        exact
        layout={MinimalLayout}
        path="/unauthorized"
      />
      <RouteWithLayout
        component={OpenIDClient.List}
        exact
        layout={MainLayout}
        path="/openid-client"
      />
      <RouteWithLayout
        component={OpenIDClient.Form}
        exact
        layout={MainLayout}
        path="/openid-client/add"
      />
      <RouteWithLayout
        component={OpenIDClient.Form}
        exact
        layout={MainLayout}
        path="/openid-client/edit/:id"
      />
      <RouteWithLayout
        component={Services.ApiList}
        exact
        layout={MainLayout}
        path="/services"
      />
      {/* <RouteWithLayout
        component={Services.ApiForm}
        exact
        layout={MainLayout}
        path="/services/apis/form/create"
      />
      <RouteWithLayout
        component={Services.ApiForm}
        exact
        layout={MainLayout}
        path="/services/apis/form/edit/:id"
      /> */}
      <RouteWithLayout
        component={Games.List}
        exact
        layout={MainLayout}
        path="/games"
      />
      <RouteWithLayout
        component={Games.Release}
        exact
        layout={MainLayout}
        path="/publishers/:publisher/games/:game/releases"
      />
      <RouteWithLayout
        component={Games.ReleaseDetail}
        exact
        layout={MainLayout}
        path="/publishers/:publisher/games/:game/releases/:version"
      />
      <RouteWithLayout
        component={EventDetail}
        exact
        layout={MainLayout}
        path="/publishers/:publisher/games/:game/events/:event"
      />
      <RouteWithLayout
        component={Event}
        exact
        layout={MainLayout}
        path="/events"
      />
      <RouteWithLayout
        component={Coupon.CouponList}
        exact
        layout={MainLayout}
        path="/coupon"
      />
      <RouteWithLayout
        component={Coupon.CouponForm}
        exact
        layout={MainLayout}
        path="/coupon/form/creator/:creator/code/:code"
      />
      <RouteWithLayout
        component={Coupon.CouponForm}
        exact
        layout={MainLayout}
        path="/coupon/form"
      />

      <RouteWithLayout
        component={RewardForm}
        exact
        layout={MainLayout}
        path="/rewards/form"
      />
      <RouteWithLayout
        component={RewardForm}
        exact
        layout={MainLayout}
        path="/rewards/form/:id"
      />
      <RouteWithLayout
        component={RewardList}
        exact
        layout={MainLayout}
        path="/rewards"
      />

      <RouteWithLayout
        component={WithdrawalForm}
        exact
        layout={MainLayout}
        path="/withdrawals/form/:id"
      />
      <RouteWithLayout
        component={WithdrawalList}
        exact
        layout={MainLayout}
        path="/withdrawals"
      />


      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
