import React, { useState, useEffect } from 'react'
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { Badge, Button, CircularProgress, Menu, MenuList, MenuItem, Typography, Link, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom'
import NotificationService from 'services/notification'
import { usePrev } from 'services/context'
import moment from 'moment'
import qs from 'query-string'
import notification from 'services/notification';
function getAction(act) {
  const a = act.split(':')
  return a
}

function getParam(p) {
  return qs.parse(p)
}

const Notification = () => {
  const location = useHistory()
  const [unreadCount, setUnreadCount] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false)
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(true)
  const prevCount = usePrev(unreadCount)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const getUnreadCount = () => {
    NotificationService
      .getUnreadNotification()
      .then((c) => {
        setUnreadCount(c)
      })
      .catch()
      .finally(() => {
      })
  }
  const loadMore = () => {

    if (notifications.length === 0) return
    NotificationService
      .getNewestNotification(notifications[notifications.length - 1].id)
      .then((n) => {
        setNotifications([...notifications, ...n])
      })
      .catch()
      .finally(() => {
      })
  }
  const getNewest = () => {
    if (notifications.length === 0) {
      setLoading(true)

      NotificationService
        .getNotification()
        .then((notifications) => {
          setNotifications(notifications)
        })
        .catch()
        .finally(() => {
          setLoading(false)
        })
      return
    }
    setLoading(true)

    NotificationService
      .getNewestNotification(notifications[0].id)
      .then((n) => {
        setNotifications([...n, ...notifications])
      })
      .catch()
      .finally(() => {
        setLoading(false)
      })
  }
  const componentDidMount = () => {
    getUnreadCount()
    setInterval(getUnreadCount, 20000)
  }
  useEffect(() => {
    componentDidMount()
  }, [])
  useEffect(() => {
    if (unreadCount === 0) return
    if (unreadCount !== prevCount) getNewest()
  }, [unreadCount])
  const action = (pos) => {
    const n = notifications[pos]
    const a = getAction(n.action)
    const param = getParam(n.param)
    if (!n.readed) {
      NotificationService.readNotification(n.id).then(
        () => {
          n.readed = true
          let notif = notifications
          notif[pos] = n
          setNotifications(notif)
          setUnreadCount(unreadCount - 1)
        }
      ).catch(() => { })
    }
    if (a[0] === 'game') {
      if (a[1] === 'event') {
        //        https://admin-portal.gameqoo.internal/publishers/atepdiaz/games/bridge-pass/releases
        location.push(`/publishers/${param.publisher_username}/games/${param.game_name}/events/${param.event_name}`)
      }
      if (a[1] === 'release') {
        location.push(`/publishers/${param.publisher_username}/games/${param.game_name}/releases`)

      }
    }if (a[0] === "withdrawals") {
      location.push('/withdrawals')
    }
  }
  return (
    <>
      <Badge badgeContent={unreadCount} onClick={handleClick} >
        <NotificationsIcon />
      </Badge>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >

        {loading && (
          <>
            <MenuItem>
              <CircularProgress />

            </MenuItem>
          </>
        )}
        {
          !loading && (
            <MenuList style={{
              alignContent: 'center',
              textAlign: 'center'
            }} onClick={() => getNewest()}>
              <li><Button style={
                {
                  alignSelf: 'center',
                  margin: '0 auto'
                }
              }>Refresh</Button>
              </li>
            </MenuList>
          )
        }
        {
          notifications.map((n, pos) => (
            <MenuList key={pos} style={
              {
                borderBottom: '1px solid #a0a0a0',
                paddingLeft: 15,
                paddingRight: 15,
                background: !n.readed ? '' : '#f0f0f0'
              }
            } onClick={() => action(pos)}>
              <Link>
                <Typography variant='h6'>{n.title}</Typography>
                <Typography style={{
                  position: 'absolute',
                  right: 15,
                  top: 7
                }} variant='h6'>{
                  moment(n.created).fromNow()
                  }</Typography>
                <Typography variant='subtitle2'>{n.message}</Typography>
              </Link>
            </MenuList>
          ))
        }
        <MenuItem onClick={() => loadMore()}>load more ...</MenuItem>

      </Menu>
    </>
  )
}

export default Notification