import { Card, CardContent, CardActions, Table, TableBody, Link, TableHead, TablePagination, Typography, makeStyles, TableRow, TableCell } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import EventService, { Score } from 'services/events'
import { Link as RouterLink } from 'react-router-dom'
import { Loader } from 'services/context'
import { Fullname } from 'components/Fullname'
const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2)
  },
  content: {
    padding: 0
  }
}))
/**
 * 
 * @type {Score[]} 
 */
const SCORES = []

const EVENT_LOADER_KEY = 'scores'
/**
 * 
 * @param {object} props 
 * @param {import('services/events').Event} props.event 
 */
const Scores = (props) => {
  const {
    startLoader,
    finishLoader,
    globalLoader
  } = Loader('globalLoader')
  const { event } = props
  const [scores, setScores] = useState(SCORES)
  const [page, setPage] = useState(
    1
  )
  const [totalRows, setTotalRows] = useState(
    0
  )
  const [size, setSize] = useState(
    10
  )

  const getScores = () => {
    startLoader(EVENT_LOADER_KEY, 'Fetching scores')
    EventService.eventScore(event.game.publisher.username, event.game.name, event.name, page, size).then(s => {
      setScores(s.data)
      setTotalRows(s.total_row)
    }).catch(e => {

    }).finally(() => {
      finishLoader(EVENT_LOADER_KEY)
    })
  }

  const componentDidMount = () => {
    getScores()
  }
  useEffect(componentDidMount, [])
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography variant="h2">{event.ended_at.getTime() < (new Date()).getTime() ? 'Temporary Scores' : 'Scores'}</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                No
              </TableCell>
              <TableCell>
                Player
              </TableCell>
              <TableCell>
                Score
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              scores.map((s, i) => (
                <TableRow key={`score-${i}`}>
                  <TableCell>
                    {i + 1}
                  </TableCell>
                  <TableCell>
                    <Link component={RouterLink} to={`/users/edit/${s.player_username}`}><Fullname username={s.player_username} /></Link>
                  </TableCell>
                  <TableCell>
                    {s.score}
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <CardActions>
          <TablePagination
            component="div"
            count={totalRows}
            onChangePage={((evt, page) => setPage(page + 1))}
            onChangeRowsPerPage={((evt, size) => setSize(size))}
            page={page - 1}
            rowsPerPage={size}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </CardContent>
    </Card>
  )

}

export default Scores