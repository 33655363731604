import React, { Component, useState, useEffect } from 'react'
import clsx from 'clsx';

import User from 'services/user'
import { makeStyles, Card, CardContent, Typography, TextField, Grid, FormControl, OutlinedInput, InputLabel, IconButton, InputAdornment, Button } from '@material-ui/core'
import AccountInfo from './components/AccountInfo';
import UserProfile from './components/UserProfile';
import ProfileAddress from './components/ProfileAddress';
import { useHistory, useParams } from 'react-router-dom';
import { Loader } from 'services/context'

/**
 * 
 * @param {DefaultTheme} theme 
 */
const useStyle = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    padding: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(2)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'right',
    marginTop: theme.spacing(1),
    flexDirection: 'column'
  },
  buttonAction: {
    display: 'flex',
    alignSelf: 'right'
  }
}))

const Form = () => {
  const classes = useStyle()
  let { id } = useParams()
  const history = useHistory()
  const [values, setValues] = useState({
    showPassword: false,
    user: {
      password: '',
      username: '',
      email: '',
      info: {
        fullname: '',
        birthdate: new Date(),
        gender: 'male',
        phone_number: '',
        address: {
          locality: '',
          state: '',
          region: '',
          postal_code: '',
          street_address: ''
        }
      },
      status: true,
      id,
    }
  })
  let isMounted = false
  const mounted = () => {
    isMounted = true
    if (id) {
      startLoader('getUser', 'Getting user data')
      User.getUser(id).then(user => {
        user.password = ''
        setValues({ ...values, user })
      }).catch(err => { })
        .finally(() => {
          finishLoader('getUser')
        })
    }
  }
  useEffect(() => {
    !isMounted && mounted()
  }, [])
  const { startLoader, finishLoader, globalLoader } = Loader('globalLoader')
  const handleSave = () => {
    if(values.user.username === '') return alert('user username cannot be empty')
    if(values.user.email === '') return alert('user username cannot be empty')
    if(values.user.info.fullname === '') return alert('user fullname cannot be empty')
    if (globalLoader.saveUser) return
    startLoader('saveUser', 'Saving user data')
    User.put(values.user.username, values.user).then(resp => {
      history.push('/users')
     }).catch(err => {
    }).finally(() => {
      finishLoader('saveUser')
      
    })

  }
  return (
    <div className={classes.root}>
      <Typography variant={'h1'}>User {id ? `Edit ${values.user.username}` : 'Add'}</Typography>
      <Grid container>
        <Grid className={classes.content} item xs={12} sm={12} md={4}>
          <AccountInfo user={values.user} isEdit={id ? true : false} validate={values.validate} onUserChange={user => setValues({ ...values, user })} />
        </Grid>
        <Grid className={classes.content} item xs={12} sm={12} md={4}>
          <UserProfile profile={values.user.info} onProfileChange={info => setValues({ ...values, user: { ...values.user, info } })} />
        </Grid>
        <Grid className={classes.content} item xs={12} sm={12} md={4}>
          <ProfileAddress address={values.user.info.address} onAddressChange={address => setValues({ ...values, user: { ...values.user, info: { ...values.user.info, address } } })} />
        </Grid>
      </Grid>
      <div className={classes.row}>
        <Button
          color="primary"
          variant="contained"
          className={classes.buttonAction}
          onClick={handleSave}
        >
          Save
        </Button>

      </div>
    </div>
  )
}

export default Form