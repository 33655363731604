import React from 'react'
import Grid from '@material-ui/core/Grid';

const ACL = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={5}>
        
      </Grid>
      <Grid item xs={2}>

      </Grid>
      <Grid item xs={5}>
      
      </Grid>
    </Grid>
  )
}

export default ACL