import { useState } from "react"
import Request from "../request"
import qs from 'querystring'
const withdrawalClients = new Request()

const useWithdrawalModule = () => {
  const [listWithdrawal, setListWithdrawal] = useState({
    data: [],
    total_row: 0
  })
  const [balance, setBalance] = useState(0)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [withdrawal, setWithdrawal] = useState({  
    bank_account_name: "",
    bank_account_number: "",
    bank_name: "",
    phone_number: "",
    priority: 0,
    publisher_username: "",
    status: "",
    withdrawal_amount: 0,
    invoice: ""
  })
  const [
    [fetchLoading, setFetchLoading],
    [statusLoading, setStatusLoading],
  ] = [
    useState(false),
    useState(false),
  ]
  
  function get(id, cb) {
    setFetchLoading(true)
    withdrawalClients
    .get(`/backend/withdrawals/${id}`)
    .then(data => {
      setWithdrawal(data.withdrawal)
      setBalance(data.balance)
      cb && cb()
    })
    .catch(e => {
    })
    .finally(() => setFetchLoading(false))
  }

  function fetch(cb) {
    setFetchLoading(true)
    withdrawalClients
    .get(`/backend/withdrawals?${qs.stringify({ page, size })}`)
    .then(data => {
      setListWithdrawal(data)
      cb && cb()
    })
    .catch(e => {

    })
    .finally(() => setFetchLoading(false))
  }

  function updateStatus(status, cb) {
    setFetchLoading(true)
    withdrawalClients
    .put(`/backend/withdrawals/${withdrawal.id}/${status}`)
    .then(data => {
      get(withdrawal.id, cb)
    })
    .catch(e => {

    })
    .finally(() => setFetchLoading(false))
  }

  function approve(cb) {
    return updateStatus('approve')
  }
  function decline(cb) {
    return updateStatus('decline')
  }

  return {
    listWithdrawal,
    page, setPage,
    size, setSize,
    withdrawal,
    balance,
    setWithdrawal,
    setListWithdrawal,
    loading: {
      fetchLoading,
      statusLoading
    },
    get,
    fetch,
    approve,
    decline
  }
}

export default useWithdrawalModule